import { useEffect, useState } from 'react'

export const useColumnsVisibility = (key: string, columns: Record<string, boolean>) => {
  const [columnVisiblity, setColumnVisibility] = useState<Record<string, boolean>>(columns)

  useEffect(() => {
    const columns = localStorage.getItem(key)
    if (columns) {
      const parsed = JSON.parse(columns)
      setColumnVisibility(parsed)
    }
  }, [])

  useEffect(() => {
    const strigified = JSON.stringify(columnVisiblity)
    localStorage.setItem(key, strigified)
  }, [columnVisiblity])

  return { columnVisiblity, setColumnVisibility }
}
