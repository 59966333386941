import {
  Length,
  IsUUID,
} from 'class-validator'

export class CreateTraitTypeDto {
  @Length(2, 50)
  name: string
}

export class UpdateTraitTypeDto extends CreateTraitTypeDto {
  @IsUUID('4')
  id: string
}

export class CreateTraitTypeResponseDto {
  id: string

  name: string
}

export class GetTraitTypeResponseDto {
  id: string

  name: string
}
