import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { MRT_ColumnDef } from 'material-react-table'
import { PaginationState } from '@tanstack/react-table'
import debounce from 'lodash.debounce'
import CustomTable from '../../../shared/Table/CustomTable'
import Form from './Form'
import { defaultPaginationConfig } from '../../../../config/pagination.config'
import { RQueryKeys } from '../../../../types/react-query'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import { useColumnsVisibility } from '../../../hooks/use-columns'
import BurnDuckTxService from '../../../../services/burn-duck-tx.service'

const service = BurnDuckTxService
const queryKey = RQueryKeys.burnDuckTransactions
const resourceName = 'transaction'
const resourceCapital = 'Transaction'

const BurnDuckTransactionView = () => {
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationConfig)
  const { columnVisiblity, setColumnVisibility } = useColumnsVisibility(`${resourceName}_columns`, {
    transactionHash: true,
    tokenId: true,
    status: true,
    user: true,
    collection: true,
    errorData: false,
    id: false,
    userId: false,
    collectionId: false
  })

  const {
    data,
    refetch,
    isLoading,
    isError,
    isFetching,
  } = useQuery([queryKey, pagination.pageIndex], async () => {
    const res = await service.list(search, pagination.pageIndex + 1)
    return res.data
  }, { keepPreviousData: true, })

  useEffect(() => {
    setPagination((x) => ({ ...x, pageIndex: 0 }))
    debounceRefetch()
  }, [search])

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
    { header: 'Hash', accessorKey: 'transactionHash' },
    { header: 'Status', accessorKey: 'status' },
    { header: 'Token ID', accessorKey: 'tokenId' },
    { header: 'User wallet', accessorKey: 'user.wallet' },
    { header: 'Collection', accessorKey: 'collection.name' },
  ], [])

  const debounceRefetch = useMemo(() => debounce(refetch, DEBOUNCE_THRESHOLD), [])

  return (
    <CustomTable
      title={resourceCapital}
      columns={columns}
      data={data || undefined}
      setPagination={setPagination}
      setSearch={setSearch}
      tableState={{
        isLoading,
        pagination,
        isError,
        isFetching,
        search
      }}
      refetch={debounceRefetch}
      Form={Form}
      setColumnsVisibility={setColumnVisibility}
      columnsVisibility={columnVisiblity}
      enabledButtons={{
        add: false,
        delete: false,
        edit: false,
        refresh: true,
      }}
    />
  )
}

export default BurnDuckTransactionView
