import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { MRT_ColumnDef } from 'material-react-table'
import { ColumnFiltersState, PaginationState } from '@tanstack/react-table'
import debounce from 'lodash.debounce'
import { TraitInstance } from '../../../../types/types'
import CustomTable from '../../../shared/Table/CustomTable'
import Form from './Form'
import { defaultPaginationConfig } from '../../../../config/pagination.config'
import { RQueryKeys } from '../../../../types/react-query'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import { useColumnsVisibility } from '../../../hooks/use-columns'
import TraitInstanceService, { FiltersTraitInstance } from '../../../../services/trait-instance.service'

const service = TraitInstanceService
const queryKey = RQueryKeys.traitInstances
const resourceName = 'trait instance'
const resourceCapital = 'Trait instance'
type Resource = TraitInstance

const TraitInstanceView = () => {
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationConfig)
  const { columnVisiblity, setColumnVisibility } = useColumnsVisibility(`${resourceName}_columns`, {
    trait: true,
    type: true,
    wallet: true,
    id: false,
    collection: true
  })
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [customFilters, setCustomFilters] = useState<FiltersTraitInstance>({
    trait: '',
    wallet: '',
    type: ''
  })

  useEffect(() => {
    const filters: any = {}
    columnFilters.forEach((x) => { filters[x.id] = x.value })
    setCustomFilters(filters)
  }, [columnFilters])

  const {
    data,
    refetch,
    isLoading,
    isError,
    isFetching,
  } = useQuery([queryKey, pagination.pageIndex, customFilters], async () => {
    const res = await service.list(search, pagination.pageIndex + 1, customFilters)
    return res.data
  }, { keepPreviousData: true, })

  const deleteMutation = useMutation(service.deleteOne, {
    onSuccess: () => queryClient.invalidateQueries(queryKey)
  })

  useEffect(() => {
    setPagination((x) => ({ ...x, pageIndex: 0 }))
    debounceRefetch()
  }, [search])

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
    {
      header: 'ID',
      accessorKey: 'id',
      enableColumnFilter: false,
    },
    {
      header: 'Wallet Addres',
      accessorKey: 'wallet',
      enableColumnFilter: true,
      accessorFn: (obj: Resource) => obj.user.wallet,
    },
    {
      header: 'Trait',
      accessorKey: 'trait',
      accessorFn: (obj: Resource) => obj.trait.name,
      enableColumnFilter: true,
    },
    {
      header: 'Type',
      accessorKey: 'type',
      accessorFn: (obj: Resource) => obj.trait.type.name,
      enableColumnFilter: true,
    },
    {
      header: 'Collection',
      accessorKey: 'collection',
      accessorFn: (obj: Resource) => obj.trait.collection.name,
      enableColumnFilter: false,
    },
  ], [])

  const debounceRefetch = useMemo(() => debounce(refetch, DEBOUNCE_THRESHOLD), [])

  return (
    <CustomTable
      title={resourceCapital}
      columns={columns}
      data={data || undefined}

      setPagination={setPagination}
      setSearch={setSearch}

      enableColumnFilters={true}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      enabledButtons={{
        edit: false,
        add: true,
        delete: true
      }}

      tableState={{
        isLoading,
        pagination,
        isError,
        isFetching,
        search
      }}
      refetch={debounceRefetch}
      Form={Form}
      setColumnsVisibility={setColumnVisibility}
      columnsVisibility={columnVisiblity}
      deleteDialogProps={{
        dialogTitle: `Delete ${resourceName}`,
        dialogContent: (obj: TraitInstance) => `Delete trait "${obj.trait.name}" from user address ${obj.user.wallet}`,
        onDelete: async (obj: TraitInstance) => deleteMutation.mutate(obj.id),
        messageAfterDelete: `${resourceCapital} Deleted.`
      }}
    />
  )
}

export default TraitInstanceView
