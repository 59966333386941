import { IsNotEmpty, IsString, IsUUID } from 'class-validator'

export class RegisterBurnTxDto {
  @IsString()
  @IsNotEmpty()
  transactionHash: string

  @IsUUID()
  userId: string
}
