import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { MRT_ColumnDef } from 'material-react-table'
import { PaginationState } from '@tanstack/react-table'
import debounce from 'lodash.debounce'
import { Collection } from '../../../../types/types'
import CustomTable from '../../../shared/Table/CustomTable'
import Form from './Form'
import { defaultPaginationConfig } from '../../../../config/pagination.config'
import { RQueryKeys } from '../../../../types/react-query'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import { useColumnsVisibility } from '../../../hooks/use-columns'
import CollectionService from '../../../../services/collection.service'

const service = CollectionService
const queryKey = RQueryKeys.collections
const resourceName = 'collection'
const resourceCapital = 'Collection'

const CollectionView = () => {
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationConfig)
  const { columnVisiblity, setColumnVisibility } = useColumnsVisibility(`${resourceName}_columns`, {
    name: true,
    id: false,
    metadataUrl: true,
    openSeaCollectionSlug: true
  })

  const {
    data,
    refetch,
    isLoading,
    isError,
    isFetching,
  } = useQuery([queryKey, pagination.pageIndex], async () => {
    const res = await service.list(search, pagination.pageIndex + 1)
    return res.data
  }, { keepPreviousData: true, })

  const deleteMutation = useMutation(service.deleteOne, {
    onSuccess: () => queryClient.invalidateQueries(queryKey)
  })

  useEffect(() => {
    setPagination((x) => ({ ...x, pageIndex: 0 }))
    debounceRefetch()
  }, [search])

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
    { header: 'Nombre', accessorKey: 'name' },
    { header: 'ID', accessorKey: 'id' },
    { header: 'Metadata URL', accessorKey: 'metadataUrl' },
    { header: 'OpenSea Slug', accessorKey: 'openSeaCollectionSlug' },
  ], [])

  const debounceRefetch = useMemo(() => debounce(refetch, DEBOUNCE_THRESHOLD), [])

  return (
    <CustomTable
      title={resourceCapital}
      columns={columns}
      data={data || undefined}
      setPagination={setPagination}
      setSearch={setSearch}
      tableState={{
        isLoading,
        pagination,
        isError,
        isFetching,
        search
      }}
      refetch={debounceRefetch}
      Form={Form}
      setColumnsVisibility={setColumnVisibility}
      columnsVisibility={columnVisiblity}
      deleteDialogProps={{
        dialogTitle: `Delete ${resourceName}`,
        dialogContent: (obj: Collection) => `Delete${resourceName} ${obj.name}`,
        onDelete: async (obj: Collection) => deleteMutation.mutate(obj.id),
        messageAfterDelete: `${resourceCapital} Deleted.`
      }}
    />
  )
}

export default CollectionView
