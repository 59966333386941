import axios from 'axios'
import AuthService from '../services/basics/auth.service'
import { ErrorData } from '../types/errors.catalogs'
import { ICredentials, User } from '../types/types'
import { clearCredentials, setCredentials } from '../utils/credentials.helper'
import { LoginDto, } from '../validations/basic/auth.dto'
import { setLoadingAC } from './loading.reducer'
import { AppDispatch } from './store'

type Login = (obj: LoginDto) => (dispatch: AppDispatch) => void
type RefreshUser = (obj: ICredentials) => (dispatch: AppDispatch) => void
type Logout = () => (dispatch: AppDispatch) => void

interface State {
  token: string,
  user: User | undefined,
  isLoggedIn: boolean,
  loginErrorCode: string | null
}

enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REFRESH_USER = 'REFRESH_USER',
  LOGIN_ERROR = 'LOGIN_ERROR',
}

type AuthAction =
  | { type: AuthActionTypes.LOGIN; payload: ICredentials }
  | { type: AuthActionTypes.LOGOUT; payload: null }
  | { type: AuthActionTypes.REFRESH_USER; payload: ICredentials }
  | { type: AuthActionTypes.LOGIN_ERROR; payload: ErrorData | null }

const loginAC = (obj: ICredentials): AuthAction => ({
  type: AuthActionTypes.LOGIN,
  payload: obj,
})

export const loginErrorAC = (obj: ErrorData | null): AuthAction => ({
  type: AuthActionTypes.LOGIN_ERROR,
  payload: obj,
})

const logoutAC = (): AuthAction => ({
  type: AuthActionTypes.LOGOUT,
  payload: null,
})

const refreshUserAC = (obj: ICredentials): AuthAction => ({
  type: AuthActionTypes.REFRESH_USER,
  payload: obj,
})

export const login: Login = (obj) => async (dispatch) => {
  dispatch(loginErrorAC(null))
  try {
    const { data } = await AuthService.login(obj)

    setCredentials(data)

    dispatch(loginAC(data))
  } catch (error: any) {
    if (!axios.isAxiosError(error)) {
      dispatch({ type: 'ERROR', payload: error.data?.message || 'Server Error' })
      return error
    }
    const errorData = error.response?.data as ErrorData
    dispatch(loginErrorAC(errorData))
    return error
  }
}

export const refreshUser: RefreshUser = (obj) => async (dispatch) => {
  try {
    //   const { data } = await AuthService.refreshUser()
    dispatch(refreshUserAC(obj))
  } catch (error: any) {
    if (!axios.isAxiosError(error)) return dispatch({ type: 'ERROR', payload: error.data?.message || 'Server Error' })

    const status = error.response?.status

    if (status === 401) {
      // if we dont pass a jwt token in the req nest will automatically return 401
      // without allowing us to send a custom error
      // that's why we handle this case by status instead of code

      clearCredentials()
      dispatch(logoutAC())
      dispatch(setLoadingAC(false))
    }
  }
}

export const logout: Logout = () => async (dispatch) => {
  try {
    // await AuthService.logout('')
  } catch (error) {
    console.log(error)
  } finally {
    clearCredentials()
    dispatch(logoutAC())
  }
}

export const istate: State = {
  token: '',
  user: undefined,
  isLoggedIn: false,
  loginErrorCode: null,
}

const reducer = (state: State = istate, action: AuthAction): State => {
  switch (action.type) {
    case AuthActionTypes.REFRESH_USER:
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        ...action.payload,
        loginErrorCode: null,
        isLoggedIn: true
      }

    case AuthActionTypes.LOGOUT:
      return {
        ...state,
      }

    case AuthActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginErrorCode: action.payload?.code || null
      }

    default: return state
  }
}

export default reducer
