import {
  Length,
  IsUUID,
  IsUrl,
  IsString,
  IsOptional,
} from 'class-validator'

export class CreateCollectionDto {
  @Length(2, 50)
  name: string

  @IsUrl()
  metadataUrl: string

  @IsString()
  @Length(2, 50)
  openSeaCollectionSlug: string
}

export class UpdateCollectionDto {
  @IsUUID('4')
  id: string

  @IsOptional()
  @Length(2, 50)
  name?: string

  @IsOptional()
  @IsUrl()
  metadataUrl?: string

  @IsOptional()
  @IsString()
  @Length(2, 50)
  openSeaCollectionSlug?: string
}

export class CreateCollectionResponseDto {
  id: string

  name: string

  metadataUrl: string

  openSeaCollectionSlug: string
}

export class GetCollectionResponseDto {
  id: string

  name: string

  metadataUrl: string

  openSeaCollectionSlug: string
}
