import * as React from 'react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  styled,
  Typography,
} from '@mui/material'

export interface FormDrawerProps {
  open?: boolean
  handleClose?: any,
  title?: string
  children?: any
  mode?: 'ADD' | 'UPDATE' | 'READ',
  Icon?: React.FC<any>
  handleSubmit?: () => any
  btnSubmitTitle?: string,
  payload?: any
  message?: string
  isFormLoading: boolean
  minWidth?: string
  submitDisabled?: boolean
}

const FormDrawer = (props: FormDrawerProps) => {
  const {
    btnSubmitTitle = 'Guardar',
    Icon,
    open,
    title,
    handleClose,
    isFormLoading,
    message,
    handleSubmit = () => { },
    minWidth = '25vw',
    submitDisabled = false
  } = props

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      // material sets an index of about 1200
      sx={{ zIndex: 1300, width: '100%' }}
    >
      <InnerContainer sx={{ minWidth }}>
        {
          Icon && <AvatarStyled>
            <Icon />
          </AvatarStyled>
        }

        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        {
          isFormLoading
          && (
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          )
        }
        {
          (!isFormLoading && message)
          && (
            <>
              <Box marginTop={2}>
                <Typography
                  variant="body1"
                  gutterBottom
                >
                  {props.message}
                </Typography>
              </Box>
              <Grid item xs>
                <SubmitButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={props.handleClose}
                >
                  Volver
                </SubmitButton>
              </Grid>
            </>
          )
        }
        {
          (!isFormLoading && !message)
          && (
            <Form>
              {props.children}
              {
                props.mode !== 'READ'
                && (
                  <SubmitButton
                    disabled={submitDisabled}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {btnSubmitTitle}
                  </SubmitButton>
                )
              }
              <Button
                onClick={props.handleClose}
              >
                Volver
              </Button>
            </Form>
          )
        }
      </InnerContainer>
    </Drawer>
  )
}

const Form = styled('form')({
  width: '70%',
  marginTop: '5%',
})

const SubmitButton = styled(Button)({
  margin: '10%'
})

const AvatarStyled = styled(Avatar)({
  margin: '10%',
})

const InnerContainer = styled('div')({
  minWidth: '25vw',
  marginTop: '5%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const CircularProgressContainer = styled('div')({
  width: '100%',
  margin: '4rem',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  flexWrap: 'wrap',
  alignItems: 'center',
})

export default FormDrawer
