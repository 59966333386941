import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { MRT_ColumnDef } from 'material-react-table'
import { PaginationState } from '@tanstack/react-table'
import debounce from 'lodash.debounce'
import { RankingDuckTypeValue, TraitType } from '../../../../types/types'
import CustomTable from '../../../shared/Table/CustomTable'
import Form from './Form'
import { defaultPaginationConfig } from '../../../../config/pagination.config'
import { RQueryKeys } from '../../../../types/react-query'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import { useColumnsVisibility } from '../../../hooks/use-columns'
import RankingDuckTypeService from '../../../../services/ranking-duck-type-value.service'

type Resource = RankingDuckTypeValue
const service = RankingDuckTypeService
const queryKey = RQueryKeys.rankingDuckTypeValues
const resourceName = 'duck type'
const resourceCapital = 'Duck type'

const DuckTypeView = () => {
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationConfig)
  const { columnVisiblity, setColumnVisibility } = useColumnsVisibility(`${resourceName}_columns`, {
    type: true,
    id: false,
    points: true,
    collection: true,
  })

  const {
    data,
    refetch,
    isLoading,
    isError,
    isFetching,
  } = useQuery([queryKey, pagination.pageIndex], async () => {
    const res = await service.list(search, pagination.pageIndex + 1)
    return res.data
  }, { keepPreviousData: true, })

  const deleteMutation = useMutation(service.deleteOne, {
    onSuccess: () => queryClient.invalidateQueries(queryKey)
  })

  useEffect(() => {
    setPagination((x) => ({ ...x, pageIndex: 0 }))
    debounceRefetch()
  }, [search])

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
    { header: 'Type', accessorKey: 'type' },
    { header: 'ID', accessorKey: 'id' },
    {
      header: 'Collection',
      accessorFn: (originalRow: Resource) => originalRow.collection.name,
      accessorKey: 'collection'
    },
    { header: 'Points', accessorKey: 'points' },
  ], [])

  const debounceRefetch = useMemo(() => debounce(refetch, DEBOUNCE_THRESHOLD), [])

  return (
    <CustomTable
      title={resourceCapital}
      columns={columns}
      data={data || undefined}
      setPagination={setPagination}
      setSearch={setSearch}
      tableState={{
        isLoading,
        pagination,
        isError,
        isFetching,
        search
      }}
      refetch={debounceRefetch}
      Form={Form}
      setColumnsVisibility={setColumnVisibility}
      columnsVisibility={columnVisiblity}
      deleteDialogProps={{
        dialogTitle: `Delete ${resourceName}`,
        dialogContent: (obj: TraitType) => `Delete ${resourceName} ${obj.name}`,
        onDelete: async (obj: TraitType) => deleteMutation.mutate(obj.id),
        messageAfterDelete: `${resourceCapital} Deleted.`
      }}
    />
  )
}

export default DuckTypeView
