import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { MRT_ColumnDef } from 'material-react-table'
import { PaginationState } from '@tanstack/react-table'
import debounce from 'lodash.debounce'
import { User } from '../../../../types/types'
import UsersService from '../../../../services/users.service'
import CustomTable from '../../../shared/Table/CustomTable'
import Form from './Form'
import { defaultPaginationConfig } from '../../../../config/pagination.config'
import { RQueryKeys } from '../../../../types/react-query'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import { useColumnsVisibility } from '../../../hooks/use-columns'

const service = UsersService
const queryKey = RQueryKeys.users
const resourceName = 'usuario'
const resourceCapital = 'Usuario'

const UserView = () => {
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationConfig)
  const { columnVisiblity, setColumnVisibility } = useColumnsVisibility(`${resourceName}_columns`, {
    fullName: true,
    email: true,
    type: true,
    wallet: true,
    id: true
  })

  const {
    data,
    refetch,
    isLoading,
    isError,
    isFetching,
  } = useQuery([queryKey, pagination.pageIndex], async () => {
    const res = await service.list(search, pagination.pageIndex + 1)
    return res.data
  }, { keepPreviousData: true, })

  const deleteMutation = useMutation(service.deleteOne, {
    onSuccess: () => queryClient.invalidateQueries(queryKey)
  })

  useEffect(() => {
    setPagination((x) => ({ ...x, pageIndex: 0 }))
    debounceRefetch()
  }, [search])

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
    { header: 'Nombre', accessorKey: 'fullName' },
    { header: 'Wallet', accessorKey: 'wallet' },
    { header: 'Email', accessorKey: 'email' },
    { header: 'Rol', accessorKey: 'type' },
    { header: 'ID', accessorKey: 'id' },
  ], [])

  const debounceRefetch = useMemo(() => debounce(refetch, DEBOUNCE_THRESHOLD), [])

  return (
    <CustomTable
      title={resourceCapital}
      columns={columns}
      data={data || undefined}
      setPagination={setPagination}
      setSearch={setSearch}
      tableState={{
        isLoading,
        pagination,
        isError,
        isFetching,
        search
      }}
      refetch={debounceRefetch}
      Form={Form}
      setColumnsVisibility={setColumnVisibility}
      columnsVisibility={columnVisiblity}
      deleteDialogProps={{
        dialogTitle: `Borrar ${resourceName}`,
        dialogContent: (obj: User) => (
          `Esta seguro que desea borrar al ${resourceName} ${obj.fullName ? obj.fullName : obj.wallet}`
        ),
        onDelete: async (obj: User) => deleteMutation.mutate(obj.id),
        messageAfterDelete: `${resourceCapital} borado correctamente.`
      }}
    />
  )
}

export default UserView
