export enum RQueryKeys {
  users = 'users',
  traitType = 'trait-type',
  traits = 'traits',
  collections = 'collections',
  traitInstances = 'trait-instances',
  magicBoxes = 'magic-boxes',
  payableEvents = 'payable-events',
  burnDuckTransactions = 'burn-duck-transactions',
  canvas = 'canvas',
  rankingDuckTypeValues = 'rankingDuckTypeValues',
}
