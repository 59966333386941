import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  CardGiftcard,
  Dashboard,
  DataObject,
  Error,
  Hub,
  LibraryBooks,
  MoneyTwoTone,
  Person,
  Summarize,
  TypeSpecimen,
} from '@mui/icons-material'
import Item from '../Item'

export const AdminSideNavList = React.memo(() => {
  const history = useHistory()
  return (
    <>
      <Item title="Dashboard" Icon={Dashboard} onClick={() => history.push('/panel/admin')} />
      <Item title="Users" Icon={Person} onClick={() => history.push('/panel/users')} />
      <Item title="Trait Types" Icon={TypeSpecimen} onClick={() => history.push('/panel/trait-types')} />
      <Item title="Collections" Icon={LibraryBooks} onClick={() => history.push('/panel/collections')} />
      <Item title="Traits" Icon={Hub} onClick={() => history.push('/panel/traits')} />
      <Item title="Trait instances" Icon={DataObject} onClick={() => history.push('/panel/trait-instances')} />
      <Item title="Magic box" Icon={CardGiftcard} onClick={() => history.push('/panel/magic-boxes')} />
      <Item title="Burn duck Errors" Icon={Error} onClick={() => history.push('/panel/burn-ducks-tx')} />
      <Item title="Canvas without body" Icon={Error} onClick={() => history.push('/panel/canvas')} />
      <Item title="Transactions" Icon={Summarize} onClick={() => history.push('/panel/transactions')} />
      <Item title="Duck types" Icon={CardGiftcard} onClick={() => history.push('/panel/duck-types')} />
      <Item title="Payable Events" Icon={MoneyTwoTone} onClick={() => history.push('/panel/payable-events')} />
    </>
  )
})
