import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Container,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import MaterialReactTable, {
  DensityState,
  MRT_Cell,
  MRT_ColumnDef,
  MRT_FullScreenToggleButton,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton
} from 'material-react-table'
import {
  ColumnFiltersState,
  PaginationState,
  VisibilityState
} from '@tanstack/react-table'
import {
  Delete,
  Edit,
  FileDownload,
  Refresh,
  Visibility
} from '@mui/icons-material'
import { ExportToCsv } from 'export-to-csv'
import DraggableDialog from '../Modals/DraggableAlertDialog'
import { Paginated } from '../../../types/response.types'

export interface CustomTableFormProps {
  open?: boolean
  handleClose?: any,
  mode?: 'ADD' | 'UPDATE' | 'READ',
  payload?: any
}

interface Props {
  title: string
  data?: Paginated<any>
  columns: MRT_ColumnDef<any>[]
  setSearch: (value: any) => void
  setPagination: (value: any) => void
  refetch: () => Promise<any> | undefined
  tableState: TableState
  enableColumnFilters?: boolean

  columnsVisibility?: VisibilityState
  setColumnsVisibility?: React.Dispatch<React.SetStateAction<any>>

  columnFilters?: ColumnFiltersState
  setColumnFilters?: React.Dispatch<React.SetStateAction<any>>

  extraActions?: {
    btnText: string
    onClick: (
      obj: any,
      setMode: React.Dispatch<React.SetStateAction<any>>
    ) => void,
    checkShouldShow: (obj: any) => boolean
  }[]

  Form: React.FC<CustomTableFormProps>
  enabledButtons?: EnabledButtons
  addButtonTitle?: string
  deleteDialogProps?: DeleteDialogProps
  positionActionsColumn?: 'last' | 'first'
}

const paginationConfig = {
  rowsPerPage: 20,
  rowsPerPageOptions: [20]
}

const reactTableInitialState = {
  showColumnFilters: false,
  showGlobalFilter: true
}

const CustomTable = (props: Props) => {
  const {
    title,
    columns,
    data,
    setSearch,
    setPagination,
    refetch,
    tableState,
    setColumnsVisibility = () => { },
    columnsVisibility,
    columnFilters = [],
    enableColumnFilters = false,
    setColumnFilters,
    deleteDialogProps,
    Form,
    extraActions,
    addButtonTitle = 'Create',
    positionActionsColumn = 'last',
    enabledButtons = {
      add: true,
      edit: true,
      delete: true,
      refresh: true
    },
  } = props

  const {
    isLoading,
    isFetching,
    isError,
    pagination,
    search
  } = tableState

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [content, setContent] = useState('')
  const [openForm, setOpenForm] = useState(false)
  const [obj, setObj] = useState<any>({})
  const [mode, setMode] = useState<'ADD' | 'UPDATE' | 'READ'>('ADD')
  const [deleteDialogLoading, setDeleteDialogLoading] = useState(false)
  const [deleteDialogMessageAfterDelete, setDeleteDialogMessageAfterDelete] = useState('')
  const [density, setDensity] = useState<DensityState>('compact')
  // #region useCallbacks

  useEffect(() => {
    let isComponentMounted = true
    if (isComponentMounted) {
      setDeleteDialogMessageAfterDelete('')
    }

    return () => {
      isComponentMounted = false
    }
  }, [obj])

  const handleAdd = () => {
    setMode('ADD')
    setOpenForm(true)
  }

  const handleUpdate = (rowData: MRT_Row<any>) => {
    setMode('UPDATE')
    setObj(rowData.original)
    setOpenForm(true)
  }

  const handleExtraAction = (rowData: MRT_Row<any>) => {
    setObj(rowData.original)
    setOpenForm(true)
  }

  const handleRead = (rowData: MRT_Row<any>) => {
    setMode('READ')
    setObj(rowData.original)
    setOpenForm(true)
  }

  const handleDelete = (rowData: MRT_Row<any>) => {
    if (!deleteDialogProps) return
    setObj(rowData.original)
    console.log(rowData.original)
    setDeleteDialogOpen(true)
    console.log(deleteDialogProps.dialogContent(rowData.original))
    setContent(deleteDialogProps.dialogContent(rowData.original))
  }

  const handleSubmitDeleteDialog = async (x: any) => {
    if (!deleteDialogProps) return
    setDeleteDialogLoading(true)
    try {
      await deleteDialogProps.onDelete(x)
      setDeleteDialogMessageAfterDelete(deleteDialogProps.messageAfterDelete)
    } catch (error) {
      setDeleteDialogMessageAfterDelete('Se produjo un error')
    }
    setDeleteDialogLoading(false)
  }

  const csvOptions = useMemo(() => ({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    filename: title
  }), [columns, title])

  const parseDataForExport = (list: any[]) => {
    const mapAccesorKeyWithLabel: {
      [x: string | symbol]: {
        header: string,
        accesorFn?: (row: any) => string
      }
    } = {}
    for (const column of columns) {
      if (!column.accessorKey) continue
      mapAccesorKeyWithLabel[column.accessorKey] = {
        header: column.header,
        accesorFn: column.accessorFn
      }
    }

    const parsedData = list.map((item) => {
      const parsedItem: any = {}

      for (const key in item) {
        if (Array.isArray(item[key])) continue

        const itemConfig = mapAccesorKeyWithLabel[key]
        if (!itemConfig) continue
        const header = itemConfig.header

        parsedItem[header] = itemConfig.accesorFn
          ? itemConfig.accesorFn(item[key])
          : item[key]
      }

      return parsedItem
    })

    return parsedData
  }

  const handleExportRows = (rows: MRT_Row[]) => {
    const csvExporter = new ExportToCsv(csvOptions)
    const originalData = rows.map((row) => row.original)
    const parsedData = parseDataForExport(originalData)
    csvExporter.generateCsv(parsedData)
  }

  const handleExportData = () => {
    if (!data || !data.rows) return
    // if (data.rows.length === 0) return

    const csvExporter = new ExportToCsv(csvOptions)
    const parsedData = parseDataForExport(data.rows)
    csvExporter.generateCsv(parsedData)
  }

  const Actions = (prop: ActionProps) => (
    <Box >
      {
        (extraActions)
        && extraActions.map((action) => (
          action.checkShouldShow(prop.row.original)
            ? <Button
              key={action.btnText}
              onClick={() => {
                action.onClick(prop.row.original, setMode)
                handleExtraAction(prop.row)
              }}
            >
              {action.btnText}
            </Button>
            : <></>
        ))

      }
      {
        enabledButtons.edit
        && <IconButton onClick={() => handleUpdate(prop.row)}>
          <Edit />
        </IconButton>
      }
      {
        enabledButtons.delete
        && <IconButton onClick={() => handleDelete(prop.row)}>
          <Delete />
        </IconButton>
      }
      <IconButton onClick={() => handleRead(prop.row)}>
        <Visibility />
      </IconButton>
    </Box>
  )

  const HeaderActions = ({ table }: { table: MRT_TableInstance<any> }) => (
    <Box>
      {
        enabledButtons.refresh
        && <Tooltip arrow title="Refresh Data">
          <IconButton onClick={refetch}>
            <Refresh />
          </IconButton>
        </Tooltip>
      }
      {
        enabledButtons.add
        && <Button
          variant='contained'
          onClick={handleAdd}
        >
          {addButtonTitle}
        </Button>
      }
      <Button
        color="primary"
        disabled={!data || data.rows.length === 0}
        // export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
        onClick={handleExportData}
        startIcon={<FileDownload />}
        variant='text'
      >
        Exportar All
      </Button>
      <Tooltip arrow title='Export selected rows'>
        <span>
          <Button
            disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
            // only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows as any)}
            startIcon={<FileDownload />}
            variant='text'
          >
            Export Selected rows
          </Button>
        </span>
      </Tooltip>
    </Box>
  )

  return (
    <>
      {
        enabledButtons.delete && deleteDialogProps
        && (
          <DraggableDialog
            messageAfterDelete={deleteDialogMessageAfterDelete}
            isLoading={deleteDialogLoading}
            title={deleteDialogProps.dialogTitle}
            open={deleteDialogOpen}
            content={content}
            btnOkTitle="Eliminar"
            handleOk={() => handleSubmitDeleteDialog(obj)}
            handleClose={() => setDeleteDialogOpen(false)}
          />
        )
      }
      <Form
        open={openForm}
        handleClose={() => setOpenForm(false)}
        mode={mode}
        payload={obj}
      />
      <Container maxWidth='xl'>
        <Typography
          variant='h4'
          marginY={3}
        >
          {title}
        </Typography>
        <MaterialReactTable
          columns={columns}
          data={data?.rows ?? []}
          manualPagination // serverside
          manualFiltering // server side filtering with search
          enableRowActions
          onPaginationChange={setPagination}
          onGlobalFilterChange={setSearch}
          renderRowActions={Actions}
          positionActionsColumn={positionActionsColumn}
          muiTablePaginationProps={paginationConfig}
          initialState={reactTableInitialState}
          renderTopToolbarCustomActions={HeaderActions}
          rowCount={data?.metadata.totalItems ?? 0}
          enableSorting={false}
          enableColumnFilters={enableColumnFilters}
          enableRowSelection
          onColumnVisibilityChange={setColumnsVisibility}
          onColumnFiltersChange={setColumnFilters}
          state={{
            isLoading,
            pagination,
            density,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            globalFilter: search,
            columnVisibility: columnsVisibility || {},
            columnFilters,
            showColumnFilters: enableColumnFilters
          }}
          onDensityChange={((value) => setDensity(value))}
          muiToolbarAlertBannerProps={
            isError
              ? {
                color: 'error',
                children: 'Error al cargar los datos',
              }
              : undefined
          }
          renderToolbarInternalActions={({ table }) => (
            <>
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_FullScreenToggleButton table={table} />
            </>
          )}

        />
      </Container>
    </>
  )
}

interface ActionProps {
  cell: MRT_Cell<any>;
  row: MRT_Row<any>;
  table: MRT_TableInstance<any>;
}

interface EnabledButtons {
  add?: boolean
  edit?: boolean
  delete?: boolean
  refresh?: boolean
}

interface TableState {
  isLoading: boolean
  pagination: PaginationState
  isError: boolean
  isFetching: boolean
  search: string
}

interface DeleteDialogProps {
  dialogTitle: string
  dialogContent: (obj: any) => string
  onDelete: (obj: any) => Promise<void>
  messageAfterDelete: string
}

export default CustomTable
