import React, { useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { Person } from '@mui/icons-material'
import { TextField, Typography } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { useBalance } from 'wagmi'
import FormDrawer from '../../../shared/Forms/FormDrawer'
import { CreateUserDto, UpdateUserDto } from '../../../../validations/user.dto'
import createValidator from '../../../../utils/class-validator-formik'
import UsersService from '../../../../services/users.service'
import { CustomTableFormProps } from '../../../shared/Table/CustomTable'
import { RQueryKeys } from '../../../../types/react-query'
import { User } from '../../../../types/types'

export const userTypes = ['admin', 'supervisor', 'operator', 'observer']

const queryKey = RQueryKeys.users

const createInitialValues: CreateUserDto = {
  fullName: '',
  email: '',
  password: '',
}

const Form = (props: CustomTableFormProps) => {
  const { payload, mode, open } = props
  const [title, setTitle] = useState('')
  const [initialValues, setInitialValues] = useState(createInitialValues)
  const [message, setMessage] = useState('')
  const queryClient = useQueryClient()
  const balance = useBalance({ address: (payload as User).wallet as any })

  const createMutation = useMutation(UsersService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setMessage('Usuario creado correctamente.')
    },
    onError: () => setMessage('Ocurrio un error')
  })

  const updateMutation = useMutation(UsersService.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setInitialValues(createInitialValues)
      setMessage('Usuario actualizado correctamente.')
    },
    onError: () => setMessage('Ocurrio un error')
  })

  useEffect(() => {
    setMessage('')
    if (mode === 'READ') setTitle('Consultar Usuario')
    if (mode === 'ADD') setTitle('Agregar Usuario')
    if (mode === 'UPDATE') setTitle('Editar Usuario')

    if (mode === 'UPDATE' || mode === 'READ') {
      setInitialValues(payload)
    }

    if (mode === 'ADD') {
      setInitialValues(createInitialValues)
    }
  }, [payload, mode, open])

  const onSubmit = async (
    values: CreateUserDto | UpdateUserDto,
    formik: FormikHelpers<CreateUserDto>
  ) => {
    if (mode === 'ADD') {
      await createMutation.mutateAsync(values as CreateUserDto)
    } else {
      await updateMutation.mutateAsync(values as UpdateUserDto)
    }
    formik.resetForm()
  }

  const validate = mode === 'ADD'
    ? createValidator(CreateUserDto)
    : createValidator(UpdateUserDto)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => (
        <FormDrawer
          message={message}
          open={props.open}
          handleClose={props.handleClose}
          title={title}
          Icon={Person}
          handleSubmit={() => formik.handleSubmit()}
          mode={props.mode}
          isFormLoading={createMutation.isLoading || updateMutation.isLoading}
        >
          {
            payload.wallet
            && <Typography>
              {`${balance.data?.formatted} ${balance.data?.symbol}`}
            </Typography>
          }
          {
            payload.wallet
            && <TextField
              type="text"
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="wallet"
              label="Wallet"
              value={payload.wallet}
              disabled={true}
            />
          }

          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="nombre"
            label="Nombre"
            name="fullName"
            value={formik.values.fullName}
            autoFocus
            onChange={formik.handleChange('fullName')}
            disabled={props.mode === 'READ'}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />

          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange('email')}
            disabled={props.mode === 'READ'}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          {
            mode !== 'READ'
            && <TextField
              type="password"
              variant="standard"
              margin="normal"
              required={mode === 'ADD'}
              fullWidth
              id="password"
              label="Contraseña"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange('password')}
              disabled={props.mode === 'READ'}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          }
        </FormDrawer>
      )}

    </Formik>
  )
}

export default Form
