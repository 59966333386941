import API, { AxiosConfig, tryGetAPIHeaders } from '../config/axios.config'
import { Paginated, Res } from '../types/response.types'
import { RankingDuckTypeValue } from '../types/types'
import { getPaginationParams } from '../utils/param.helper'
import { CreateRankingDuckTypeValueDto, UpdateRankingDuckTypeValueDto } from '../validations/admin-ranking.dto'

const resourcePath = 'admin/ranking-duck-types'
type Resource = RankingDuckTypeValue

const create = async (dto: CreateRankingDuckTypeValueDto): Promise<Res<Resource>> => {
  const res = await API.post<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  return res.data
}

const update = async (dto: UpdateRankingDuckTypeValueDto): Promise<Res<Resource>> => {
  const res = await API.put<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  return res.data
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${resourcePath}/${id}`, AxiosConfig())
}

const list = async (search: string, page: number): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(resourcePath, {
    params: getPaginationParams(search, page),
    headers: tryGetAPIHeaders()
  })
  return res.data
}

const RankingDuckTypeService = {
  create,
  update,
  deleteOne,
  list
}

export default RankingDuckTypeService
