import React, { useEffect, useMemo, useState } from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { CreditCard } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import debounce from 'lodash.debounce'
import FormDrawer from '../../../shared/Forms/FormDrawer'
import createValidator from '../../../../utils/class-validator-formik'
import { RQueryKeys } from '../../../../types/react-query'
import { CustomTableFormProps } from '../../../shared/Table/CustomTable'
import TraitInstanceService from '../../../../services/trait-instance.service'
import { CreateTraitInstanceDto } from '../../../../validations/trait-instance.dto'
import UsersService from '../../../../services/users.service'
import TraitService from '../../../../services/trait.service'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import { Trait, TraitInstance, User } from '../../../../types/types'

const service = TraitInstanceService
const queryKey = RQueryKeys.traitInstances
const titleType = 'Trait instance'

const createInitialValues: CreateTraitInstanceDto = {
  userId: '',
  traitIds: []
}

const Form = (props: CustomTableFormProps) => {
  const { payload, mode, open } = props
  const [title, setTitle] = useState('')
  const [initialValues, setInitialValues] = useState(createInitialValues)
  const [message, setMessage] = useState('')
  const queryClient = useQueryClient()

  const [searchTraits, setSearchTraits] = useState('')
  const [searchUser, setSearchUser] = useState('')

  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [selectedTraits, setSelectedTraits] = useState<Trait[]>([])
  const [traits, setTraits] = useState<Trait[]>([])
  const [users, setUsers] = useState<User[]>([])

  const userQuery = useQuery(RQueryKeys.users, async () => {
    const res = await UsersService.list(searchUser, 0)
    return res.data
  }, { keepPreviousData: true })

  const traitQuery = useQuery(RQueryKeys.traits, async () => {
    const res = await TraitService.list(searchTraits, 0)
    return res.data
  }, { keepPreviousData: true })

  const createMutation = useMutation(service.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setMessage(`${titleType} sucessfully created.`)
    },
    onError: () => setMessage('An error has occurred')
  })

  useEffect(() => {
    const data = payload as TraitInstance
    setMessage('')
    if (mode === 'READ') setTitle(`${titleType}`)
    if (mode === 'ADD') setTitle(`Create ${titleType}`)

    if (mode === 'READ') {
      console.log(data)
      setInitialValues({
        userId: data.user.id,
        traitIds: [data.trait.id]
      })
      setSelectedUser(data.user)
      setSelectedTraits([data.trait])
    }

    if (mode === 'ADD') {
      setSelectedTraits([])
      setSelectedUser(null)
      setInitialValues(createInitialValues)
    }
  }, [payload, mode, open])

  const debounceRefetchUser = useMemo(() => debounce(userQuery.refetch, DEBOUNCE_THRESHOLD), [])
  const debounceRefetchTrait = useMemo(() => debounce(traitQuery.refetch, DEBOUNCE_THRESHOLD), [])

  const handleSelectTraits = (formik: FormikProps<CreateTraitInstanceDto>, values: Trait[]) => {
    setSelectedTraits(values)
    const ids = values.map((x) => x.id)
    formik.setFieldValue('traitIds', ids)
  }

  const handleSelectUser = (formik: FormikProps<CreateTraitInstanceDto>, values: User | null) => {
    setSelectedUser(values)
    formik.setFieldValue('userId', values?.id)
  }

  useEffect(() => {
    debounceRefetchTrait()
  }, [searchTraits])

  useEffect(() => {
    debounceRefetchUser()
  }, [searchUser])

  useEffect(() => {
    if (traitQuery.data) {
      const data = traitQuery.data.rows.filter(
        (x) => !selectedTraits.find((selected) => selected.id === x.id)
      )

      setTraits(data)
    }
  }, [traitQuery.data, selectedTraits])

  useEffect(() => {
    if (userQuery.data) {
      const data = userQuery.data.rows.filter((x) => x.type === 'user')
      setUsers(data)
    }
  }, [userQuery.data])

  const onSubmit = async (
    values: CreateTraitInstanceDto,
    formik: FormikHelpers<CreateTraitInstanceDto>
  ) => {
    await createMutation.mutateAsync(values)
    formik.resetForm()
    setSelectedTraits([])
    setSelectedUser(null)
  }

  const validate = createValidator(CreateTraitInstanceDto)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => (
        <FormDrawer
          message={message}
          open={props.open}
          handleClose={props.handleClose}
          title={title}
          Icon={CreditCard}
          handleSubmit={() => formik.handleSubmit()}
          mode={props.mode}
          isFormLoading={createMutation.isLoading}
        >
          <Autocomplete
            loading={userQuery.isLoading}
            fullWidth
            id="combo-box-user"
            options={users}
            getOptionLabel={(option: User) => `${option.wallet}`}
            renderInput={
              (params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label="User"
                fullWidth
                variant="standard"
                error={formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={formik.touched.userId && formik.errors.userId}
              />
            }
            onInputChange={(_, value) => setSearchUser(value)}
            disabled={props.mode === 'READ'}
            onChange={(e: any, value: User | null) => {
              handleSelectUser(formik, value)
            }}
            value={selectedUser}
          />

          <Autocomplete
            loading={traitQuery.isLoading}
            multiple
            fullWidth
            id="combo-box-trait"
            options={traits}
            getOptionLabel={(option: Trait) => `${option.name} (${option.type.name}) [${option.collection.name}]`}
            renderInput={
              (params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label="Traits"
                fullWidth
                variant="standard"
                error={formik.touched.traitIds && Boolean(formik.errors.traitIds)}
                helperText={formik.touched.traitIds && formik.errors.traitIds}
              />
            }
            onInputChange={(_, value) => setSearchTraits(value)}
            disabled={props.mode === 'READ'}
            onChange={(e: any, value: Trait[]) => handleSelectTraits(formik, value)}
            value={selectedTraits}
          />
        </FormDrawer>
      )}

    </Formik>
  )
}

export default Form
