import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { MRT_ColumnDef } from 'material-react-table'
import { PaginationState } from '@tanstack/react-table'
import debounce from 'lodash.debounce'
import { MagicBox } from '../../../../types/types'
import CustomTable from '../../../shared/Table/CustomTable'
import Form from './Form/Form'
import { defaultPaginationConfig } from '../../../../config/pagination.config'
import { RQueryKeys } from '../../../../types/react-query'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import { useColumnsVisibility } from '../../../hooks/use-columns'
import MagicBoxService from '../../../../services/magic-box.service'

const service = MagicBoxService
const queryKey = RQueryKeys.magicBoxes
const resourceName = 'magic box'
const resourceCapital = 'Magic box'
type Resource = MagicBox

const MagicBoxView = () => {
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<PaginationState>(defaultPaginationConfig)
  const { columnVisiblity, setColumnVisibility } = useColumnsVisibility(`${resourceName}_columns`, {
    name: true,
    id: false,
    imageUrl: false,
    items: true,
    price: true,
    color: true
  })

  const {
    data,
    refetch,
    isLoading,
    isError,
    isFetching,
  } = useQuery([queryKey, pagination.pageIndex], async () => {
    const res = await service.list(search, pagination.pageIndex + 1)
    return res.data
  }, { keepPreviousData: true, })

  const deleteMutation = useMutation(service.deleteOne, {
    onSuccess: () => queryClient.invalidateQueries(queryKey)
  })

  useEffect(() => {
    setPagination((x) => ({ ...x, pageIndex: 0 }))
    debounceRefetch()
  }, [search])

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
    { header: 'ID', accessorKey: 'id' },
    { header: 'Name', accessorKey: 'name' },
    { header: 'Text Color', accessorKey: 'color' },
    { header: 'Image URL', accessorKey: 'imageUrl' },
    { header: 'Price', accessorKey: 'price' },
    {
      header: 'Items',
      accessorFn: (originalRow: Resource) => originalRow.items.length,
      accessorKey: 'items'
    },
  ], [])

  const debounceRefetch = useMemo(() => debounce(refetch, DEBOUNCE_THRESHOLD), [])

  return (
    <CustomTable
      title={resourceCapital}
      columns={columns}
      data={data || undefined}
      setPagination={setPagination}
      setSearch={setSearch}
      tableState={{
        isLoading,
        pagination,
        isError,
        isFetching,
        search
      }}
      refetch={debounceRefetch}
      Form={Form}
      setColumnsVisibility={setColumnVisibility}
      columnsVisibility={columnVisiblity}
      deleteDialogProps={{
        dialogTitle: `Delete ${resourceName}`,
        dialogContent: (obj: Resource) => `Delete ${resourceName} ${obj.name}`,
        onDelete: async (obj: Resource) => deleteMutation.mutate(obj.id),
        messageAfterDelete: `${resourceCapital} Deleted.`
      }}
    />
  )
}

export default MagicBoxView
