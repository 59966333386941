import React, { useEffect, useState } from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { CreditCard } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import FormDrawer from '../../../shared/Forms/FormDrawer'
import createValidator from '../../../../utils/class-validator-formik'
import { RQueryKeys } from '../../../../types/react-query'
import { CustomTableFormProps } from '../../../shared/Table/CustomTable'
import PayableEventService from '../../../../services/payable-event.service'
import { CreatePayableEventDto, UpdatePayableEventDto } from '../../../../validations/payable-event.dto'

const service = PayableEventService
const queryKey = RQueryKeys.payableEvents
const titleType = 'Payable Event'

const createInitialValues: CreatePayableEventDto = {
  name: '',
  description: '',
  recipientWallet: '',
  price: 0
}

const Form = (props: CustomTableFormProps) => {
  const { payload, mode, open } = props
  const [title, setTitle] = useState('')
  const [initialValues, setInitialValues] = useState(createInitialValues)
  const [message, setMessage] = useState('')
  const queryClient = useQueryClient()

  const createMutation = useMutation(service.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setMessage(`${titleType} sucessfully created.`)
    },
    onError: () => setMessage('An error has occurred')
  })

  const updateMutation = useMutation(service.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setInitialValues(createInitialValues)
      setMessage(`${titleType} Updated.`)
    },
    onError: () => setMessage('An Error has occurred')
  })

  useEffect(() => {
    setMessage('')
    if (mode === 'READ') setTitle(`${titleType}`)
    if (mode === 'ADD') setTitle(`Create ${titleType}`)
    if (mode === 'UPDATE') setTitle(`Update ${titleType}`)

    if (mode === 'UPDATE' || mode === 'READ') {
      setInitialValues(payload)
    }

    if (mode === 'ADD') {
      setInitialValues(createInitialValues)
    }
  }, [payload, mode, open])

  const onSubmit = async (
    values: CreatePayableEventDto | UpdatePayableEventDto,
    formik: FormikHelpers<CreatePayableEventDto>
  ) => {
    if (mode === 'ADD') {
      await createMutation.mutateAsync(values as CreatePayableEventDto)
    } else {
      await updateMutation.mutateAsync(values as UpdatePayableEventDto)
    }
    formik.resetForm()
  }

  const handleSetPrice = (str: string, formik: FormikProps<CreatePayableEventDto>) => {
    const value = parseFloat(str)
    if (Number.isNaN(value)) return

    formik.setFieldValue('price', value)
  }

  const validate = mode === 'ADD'
    ? createValidator(CreatePayableEventDto)
    : createValidator(UpdatePayableEventDto)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => (
        <FormDrawer
          message={message}
          open={props.open}
          handleClose={props.handleClose}
          title={title}
          Icon={CreditCard}
          handleSubmit={() => formik.handleSubmit()}
          mode={props.mode}
          isFormLoading={createMutation.isLoading || updateMutation.isLoading}
        >
          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formik.values.name}
            autoFocus
            onChange={formik.handleChange('name')}
            disabled={props.mode === 'READ'}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            value={formik.values.description}
            autoFocus
            onChange={formik.handleChange('description')}
            disabled={props.mode === 'READ'}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />

          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="recipientWallet"
            label="Recipient Wallet"
            name="recipientWallet"
            value={formik.values.recipientWallet}
            autoFocus
            onChange={formik.handleChange('recipientWallet')}
            disabled={props.mode === 'READ'}
            error={formik.touched.recipientWallet && Boolean(formik.errors.recipientWallet)}
            helperText={formik.touched.recipientWallet && formik.errors.recipientWallet}
          />

          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="price"
            label="Price"
            name="price"
            value={formik.values.price.toString()}
            onChange={(e) => handleSetPrice(e.target.value, formik)}
            disabled={props.mode === 'READ'}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
          />
        </FormDrawer>
      )}

    </Formik>
  )
}

export default Form
