import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  debounce,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import { Stack } from '@mui/system'
import axios from 'axios'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import BurnDuckTxService from '../../../../services/burn-duck-tx.service'
import MagicBoxService from '../../../../services/magic-box.service'
import UsersService from '../../../../services/users.service'
import { ErrorData } from '../../../../types/errors.catalogs'
import { RQueryKeys } from '../../../../types/react-query'
import { MagicBox, User } from '../../../../types/types'
import createValidator from '../../../../utils/class-validator-formik'
import { RegisterMagicBoxTxDto } from '../../../../validations/magic-box.dto'

const createInitialValues: RegisterMagicBoxTxDto = {
  transactionHash: '',
  userId: '',
  magicBoxId: ''
}

const RegisterMagicBoxBuyTx = () => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [selectedMagicbox, setSelectedMagicbox] = useState<MagicBox | null>(null)
  const [searchUser, setSearchUser] = useState('')
  const [searchMagicbox, setSearchMagicbox] = useState('')
  const [users, setUsers] = useState<User[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const userQuery = useQuery('users-mb', async () => {
    const res = await UsersService.list(searchUser, 0)
    return res.data
  }, { keepPreviousData: true })

  const magicboxQuery = useQuery(RQueryKeys.magicBoxes, async () => {
    const res = await MagicBoxService.list(searchMagicbox, 0)
    return res.data
  }, { keepPreviousData: true })

  const debounceRefetchUser = useMemo(() => debounce(userQuery.refetch, DEBOUNCE_THRESHOLD), [])
  const debounceRefetchMagicbox = useMemo(() => debounce(magicboxQuery.refetch, DEBOUNCE_THRESHOLD), [])

  const registerMutation = useMutation(BurnDuckTxService.registerTx, {
    onSuccess: () => {
      enqueueSnackbar('Magic bos tx registered.')
    },
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const errorData = err.response?.data as ErrorData
        console.log(errorData)
        enqueueSnackbar(errorData.message)
      }
    }
  })

  useEffect(() => {
    if (userQuery.data) {
      const data = userQuery.data.rows.filter((x) => x.type === 'user')
      setUsers(data)
    }
  }, [userQuery.data])

  useEffect(() => {
    debounceRefetchUser()
  }, [searchUser])

  useEffect(() => {
    debounceRefetchMagicbox()
  }, [searchMagicbox])

  const handleSelectUser = (formik: FormikProps<RegisterMagicBoxTxDto>, values: User | null) => {
    setSelectedUser(values)
    formik.setFieldValue('userId', values?.id)
  }

  const handleSelectMagicbox = (formik: FormikProps<RegisterMagicBoxTxDto>, values: MagicBox | null) => {
    setSelectedMagicbox(values)
    formik.setFieldValue('magicBoxId', values?.id)
  }

  const onSubmit = async (
    values: RegisterMagicBoxTxDto,
    formik: FormikHelpers<RegisterMagicBoxTxDto>
  ) => {
    await registerMutation.mutateAsync(values)
    formik.resetForm()
    setSelectedUser(null)
    setSelectedMagicbox(null)
  }

  const validate = createValidator(RegisterMagicBoxTxDto)

  return (
    <Paper sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '30rem'
    }}>

      <Typography>Magic box transactions</Typography>

      <Formik
        enableReinitialize
        initialValues={createInitialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formik) => (
          <Stack gap={'1rem'} width='80%'>
            <TextField
              type="text"
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="transactionHash"
              label="transactionHash"
              name="transactionHash"
              value={formik.values.transactionHash}
              autoFocus
              onChange={formik.handleChange('transactionHash')}
              error={formik.touched.transactionHash && Boolean(formik.errors.transactionHash)}
              helperText={formik.touched.transactionHash && formik.errors.transactionHash}
            />
            <Autocomplete
              loading={magicboxQuery.isLoading}
              fullWidth
              id="combo-box-mb"
              options={magicboxQuery.data?.rows || []}
              getOptionLabel={(option: MagicBox) => `${option.name}`}
              renderInput={
                (params: AutocompleteRenderInputParams) => <TextField
                  {...params}
                  label="Magic box"
                  fullWidth
                  variant="standard"
                  error={formik.touched.magicBoxId && Boolean(formik.errors.magicBoxId)}
                  helperText={formik.touched.magicBoxId && formik.errors.magicBoxId}
                />
              }
              onInputChange={(_, value) => setSearchMagicbox(value)}
              onChange={(e: any, value: MagicBox | null) => {
                handleSelectMagicbox(formik, value)
              }}
              value={selectedMagicbox}
            />

            <Autocomplete
              loading={userQuery.isLoading}
              fullWidth
              id="combo-box-user"
              options={users}
              getOptionLabel={(option: User) => `${option.wallet}`}
              renderInput={
                (params: AutocompleteRenderInputParams) => <TextField
                  {...params}
                  label="User"
                  fullWidth
                  variant="standard"
                  error={formik.touched.userId && Boolean(formik.errors.userId)}
                  helperText={formik.touched.userId && formik.errors.userId}
                />
              }
              onInputChange={(_, value) => setSearchUser(value)}
              onChange={(e: any, value: User | null) => {
                handleSelectUser(formik, value)
              }}
              value={selectedUser}
            />

            <Button
              onClick={() => formik.handleSubmit()}
              disabled={registerMutation.isLoading}
            >
              Submit
            </Button>
          </Stack>
        )}

      </Formik>
    </Paper >
  )
}

export default RegisterMagicBoxBuyTx
