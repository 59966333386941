import API, { AxiosConfig, tryGetAPIHeaders } from '../config/axios.config'
import { Paginated, Res } from '../types/response.types'
import { BurnDuckTransaction } from '../types/types'
import { getPaginationParams } from '../utils/param.helper'
import { RegisterBurnTxDto } from '../validations/burn-duck.dto'

const resourcePath = 'admin/burn-ducks'
type Resource = BurnDuckTransaction

const markAsCompleted = async (id: string): Promise<void> => {
  await API.put(`${resourcePath}/${id}`, {}, AxiosConfig())
}

const list = async (search: string, page: number): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(resourcePath, {
    params: getPaginationParams(search, page),
    headers: tryGetAPIHeaders()
  })
  return res.data
}

const registerTx = async (dto: RegisterBurnTxDto): Promise<void> => {
  await API.post(`${resourcePath}/register`, dto, AxiosConfig())
}

const BurnDuckTxService = {
  markAsCompleted,
  list,
  registerTx
}

export default BurnDuckTxService
