import React, { useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import {
  useMutation,
  useQueryClient
} from 'react-query'
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material'
import {
  ArrowLeft,
  ArrowRight,
  CardGiftcard
} from '@mui/icons-material'
import createValidator from '../../../../../utils/class-validator-formik'
import { RQueryKeys } from '../../../../../types/react-query'
import { CustomTableFormProps } from '../../../../shared/Table/CustomTable'
import {
  MagicBox,
  Trait,
} from '../../../../../types/types'
import {
  CreateMagicBoxDto,
  UpdateMagicBoxDto
} from '../../../../../validations/magic-box.dto'
import MagicBoxService from '../../../../../services/magic-box.service'
import FormDrawerSimple from '../../../../shared/Forms/FormDrawerSimple'
import { StepBasicInfo } from './StepBasicInfo'
import { StepSelectTraits } from './StepSelectTraits'

const service = MagicBoxService
const queryKey = RQueryKeys.magicBoxes
const titleType = 'Magic Box'

const steps = [
  'Info',
  'Add items'
]

const createInitialValues: CreateMagicBoxDto = {
  name: '',
  price: 0,
  traitIds: [],
  color: ''
}

export interface SelectedTrait {
  itemId: string // this is used to have unique values in the list, allowing repeated traits
  trait: Trait
}

const Form = (props: CustomTableFormProps) => {
  const { payload, mode, open } = props
  const [title, setTitle] = useState('')
  const [initialValues, setInitialValues] = useState(createInitialValues)
  const [message, setMessage] = useState('')
  const [step, setStep] = useState(0)

  const [file, setFile] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string | null>(null)

  const [selectedTraits, setSelectedTraits] = useState<SelectedTrait[]>([])

  const queryClient = useQueryClient()

  const createMutation = useMutation(service.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setMessage(`${titleType} sucessfully created.`)
    },
    onError: () => setMessage('An error has occurred')
  })

  const updateMutation = useMutation(service.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setInitialValues(createInitialValues)
      setMessage(`${titleType} Updated.`)
    },
    onError: () => setMessage('An Error has occurred')
  })

  useEffect(() => {
    setMessage('')
    if (mode === 'READ') setTitle(`${titleType}`)
    if (mode === 'ADD') setTitle(`Create ${titleType}`)
    if (mode === 'UPDATE') setTitle(`Update ${titleType}`)
    setStep(0)

    if (mode === 'UPDATE' || mode === 'READ') {
      const data = payload as MagicBox
      setInitialValues({
        ...data,
        traitIds: data.items.map((x) => x.trait.id),
        price: +data.price
      })
      setImagePreview(data.imageUrl || null)

      const selectedTraits = data.items.map<SelectedTrait>((item) => ({
        itemId: item.id,
        trait: item.trait
      }))

      setSelectedTraits(selectedTraits)
    }

    if (mode === 'ADD') {
      setInitialValues(createInitialValues)
      setSelectedTraits([])
      setImagePreview(null)
      setFile(null)
    }
  }, [payload, mode, open])

  useEffect(() => {
    if (file) {
      const img = URL.createObjectURL(file)
      setImagePreview(img)
    } else {
      setImagePreview(null)
    }
  }, [file])

  const onSubmit = async (
    values: CreateMagicBoxDto | UpdateMagicBoxDto,
    formik: FormikHelpers<CreateMagicBoxDto>
  ) => {
    if (mode === 'ADD' && !file) return

    values.traitIds = selectedTraits.map((x) => x.trait.id)

    if (mode === 'ADD') {
      await createMutation.mutateAsync({
        dto: values as CreateMagicBoxDto,
        image: file!
      })
    } else {
      await updateMutation.mutateAsync({
        dto: values as UpdateMagicBoxDto,
        image: file
      })
    }
    setFile(null)
    setImagePreview(null)
    setSelectedTraits([])
    setStep(0)
    formik.resetForm()
  }

  const validate = mode === 'ADD'
    ? createValidator(CreateMagicBoxDto)
    : createValidator(UpdateMagicBoxDto)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => (
        <FormDrawerSimple
          message={message}
          open={props.open}
          handleClose={props.handleClose}
          Icon={CardGiftcard}
          mode={props.mode}
          isFormLoading={createMutation.isLoading || updateMutation.isLoading}
          minWidth='40vw'
        >
          <Box
            display={'flex'}
            justifyContent='center'
            alignItems={'center'}
            marginBottom={5}
            gap={1}
          >
            <Typography
              variant='h5'
              align={'center'}
            >
              {title}
            </Typography>
          </Box>
          <Stepper activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {
              step === 0 && <StepBasicInfo
                formik={formik}
                imagePreview={imagePreview}
                setImagePreview={setImagePreview}
                file={file}
                setFile={setFile}
                mode={mode}
              />
            }
            {
              step === 1 && <StepSelectTraits
                formik={formik}
                selectedTraits={selectedTraits}
                setSelectedTraits={setSelectedTraits}
                mode={mode}
              />
            }
          </Box>

          <Box
            position={'absolute'}
            bottom={10}
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems='center'
            gap={1}
          >
            {
              step > 0
              && <Button
                startIcon={<ArrowLeft />}
                onClick={() => setStep((x) => x - 1)}
                variant='text'
              >
                Atras
              </Button>

            }
            {
              step === 0
                ? <Button
                  endIcon={<ArrowRight />}
                  onClick={() => setStep((x) => x + 1)}
                  variant='contained'
                >
                  Siguiente
                </Button>

                : props.mode !== 'READ' && <Button
                  endIcon={<ArrowRight />}
                  onClick={() => formik.handleSubmit()}
                  variant='contained'
                >
                  Guardar
                </Button>
            }
          </Box>

        </FormDrawerSimple>
      )}

    </Formik>
  )
}

export default Form
