import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
} from '@mui/material'
import {
  AccountCircle,
  ChevronLeft,
  ExitToApp,
  Menu as MenuIcon
} from '@mui/icons-material'
import { connect, ConnectedProps } from 'react-redux'
import Item from './Item'
import AlertDialog from '../Modals/AlertDialog'
import FondoImg from '../../../assets/images/nodes.png'
import { RootState } from '../../../redux/root.reducer'
import { setLogoutDialog } from '../../../redux/logout.reducer'
import { logout } from '../../../redux/auth.reducer'
import { UserType } from '../../../types/types'
import { AdminSideNavList } from './NavList/AdminSideNavList'

interface SideNavbarProps {
  children: any
  title: string
}

const drawerWidth = 240

const SideNavbar = (props: Props) => {
  const { user } = props

  const history = useHistory()
  const [open, setOpen] = React.useState(false) // estado del side menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openAccountMenu = Boolean(anchorEl)
  const [isLoading, setIsLoading] = useState(false)

  // #region handlers del account menu

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClickPerfil = () => {
    setAnchorEl(null)
    history.push('/panel/perfil')
  }
  const handleClickCerrarSesion = () => {
    setAnchorEl(null)
    props.setIsLogoutDialogOpen(true)
  }
  // #endregion
  // #region handlers del sidenav
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  // #endregion

  const handleLogout = async () => {
    setIsLoading(true)
    await props.onLogout()
    history.push('/auth')
  }

  return (
    <Box sx={{ display: 'flex' }}>

      <CssBaseline />
      <AppBarStyled
        position="absolute"
        open={open}
      >
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {props.title}
          </Typography>
          <div>
            <MenuButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              open={open}
            >
              <AccountCircle />
            </MenuButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openAccountMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClickPerfil}>Perfil</MenuItem>
              <MenuItem onClick={handleClickCerrarSesion}>Cerrar sesion</MenuItem>

            </Menu>
          </div>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled
        variant="permanent"
        open={open}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          {
            (user?.type === UserType.admin) && <AdminSideNavList />
          }
        </List>
        <Divider />
        <List>
          <Item
            title="Cerrar Sesion"
            Icon={ExitToApp}
            onClick={() => props.setIsLogoutDialogOpen(true)}
          />
        </List>
      </DrawerStyled>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900]),
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <DrawerHeader />
        <AlertDialog
          open={props.isLogoutDialogOpen}
          content="Desea cerrar su sesion ?"
          isLoading={isLoading}
          handleOk={handleLogout}
          btnOkTitle="Cerrar sesion"
          handleClose={() => props.setIsLogoutDialogOpen(false)}
        />
        <BackdropStyled
          open={props.isTokenExpired}
          onClick={() => { }}
        >
          <Typography variant="h3">
            Sesion caducada.
          </Typography>
          <CircularProgress color="inherit" />
        </BackdropStyled>
        {props.children}
      </Box>
    </Box>
  )
}

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const MenuButton = styled(IconButton)<{ open: boolean }>(({ theme, open }) => ({
  marginRight: theme.spacing(2),
  display: open ? 'none' : undefined
}))

const DrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
)

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MainContentContainer = styled('div')({
  backgroundImage: `url(${FondoImg})`,
  backgroundRepeat: 'no-repeat',
})

const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
}))

const mapStateToProps = (state: RootState) => ({
  isLogoutDialogOpen: state.logout.isLogoutDialogOpen,
  isTokenExpired: state.logout.isTokenExpired,
  user: state.auth.user
})
const mapDispatchToProps = (dispatch: any) => ({
  setIsLogoutDialogOpen: (obj: boolean) => dispatch(setLogoutDialog(obj)),
  onLogout: async () => dispatch(logout()),
})
const connectToStore = connect(mapStateToProps, mapDispatchToProps)
interface Props extends ConnectedProps<typeof connectToStore>, SideNavbarProps { }

export default connectToStore(SideNavbar)
