import {
  Length,
  IsUUID,
  IsString,
  IsOptional,
  IsNumber,
  Min,
} from 'class-validator'

export class CreatePayableEventDto {
  @Length(2, 50)
  name: string

  @IsOptional()
  @Length(2, 50)
  description?: string

  @IsNumber()
  @Min(0)
  price: number

  @IsString()
  recipientWallet: string
}

export class UpdatePayableEventDto {
  @IsUUID()
  id: string

  @IsOptional()
  @Length(2, 50)
  name?: string

  @IsOptional()
  @Length(2, 50)
  description?: string

  @IsOptional()
  @IsNumber()
  @Min(0)
  price?: number

  @IsOptional()
  @IsString()
  recipientWallet?: string
}
