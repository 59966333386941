import { combineReducers } from 'redux'
import authReducer from './auth.reducer'
import loadingReducer from './loading.reducer'
import logoutReducer from './logout.reducer'

// Add reducers here
const reducers = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  logout: logoutReducer
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') state = undefined
  return reducers(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
