import API, { AxiosConfig, tryGetAPIHeaders } from '../config/axios.config'
import { Paginated, Res } from '../types/response.types'
import { User } from '../types/types'
import { getPaginationParams } from '../utils/param.helper'
import { CreateUserDto, UpdateUserDto } from '../validations/user.dto'

const resourcePath = '/users'
type Resource = User

const create = async (dto: CreateUserDto): Promise<Res<Resource>> => {
  const res = await API.post<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  return res.data
}

const update = async (dto: UpdateUserDto): Promise<Res<Resource>> => {
  const res = await API.put<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  return res.data
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${resourcePath}/${id}`, AxiosConfig())
}

const list = async (search: string, page: number): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(resourcePath, {
    params: getPaginationParams(search, page),
    headers: tryGetAPIHeaders()
  })
  return res.data
}

const UsersService = {
  create,
  update,
  deleteOne,
  list
}

export default UsersService
