import {
  IsArray,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Length,
  Min
} from 'class-validator'

export class CreateMagicBoxDto {
  @IsString()
  @Length(2, 30)
  name: string

  @IsNumber()
  @Min(0)
  price: number

  @IsString()
  color: string

  @IsArray()
  traitIds: string[]
}

export class UpdateMagicBoxDto {
  @IsUUID()
  id: string

  @IsOptional()
  @IsString()
  @Length(2, 30)
  name?: string

  @IsOptional()
  @IsNumber()
  @Min(0)
  price?: number

  @IsOptional()
  @IsString()
  color?: string

  @IsOptional()
  @IsArray()
  traitIds?: string[]
}

export class RegisterMagicBoxTxDto {
  @IsString()
  @IsNotEmpty()
  transactionHash: string

  @IsUUID()
  magicBoxId: string

  @IsUUID()
  userId: string
}
