import API, { AxiosConfig, tryGetAPIHeaders } from '../config/axios.config'
import { Paginated, Res } from '../types/response.types'
import { TraitInstance } from '../types/types'
import { getPaginationParams } from '../utils/param.helper'
import { CreateTraitInstanceDto } from '../validations/trait-instance.dto'

export interface FiltersTraitInstance {
  wallet: string
  trait: string
  type: string
}

const resourcePath = 'admin/trait-instances'
type Resource = TraitInstance

const create = async (dto: CreateTraitInstanceDto): Promise<Res<Resource>> => {
  const res = await API.post<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  return res.data
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${resourcePath}/${id}`, AxiosConfig())
}

const list = async (search: string, page: number, filters: FiltersTraitInstance): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(resourcePath, {
    params: {
      ...getPaginationParams(search, page),
      ...filters
    },
    headers: tryGetAPIHeaders()
  })
  return res.data
}

const TraitInstanceService = {
  create,
  deleteOne,
  list
}

export default TraitInstanceService
