import { Switch, Route } from 'react-router-dom'
import {
  CssBaseline,
  Grid,
  Paper,
  styled
} from '@mui/material'
import LoginForm from './LoginForm'
import FondoLogo from '../../../assets/images/nodes.png'

const Auth = () => (
  <GridRoot container>
    <CssBaseline />
    <GridWithImage item sm={4} md={8} />
    <Grid
      item
      xs={12}
      sm={8}
      md={4}
      component={Paper}
      elevation={6}
      square
    >
      <DivPaper>
        <Switch>
          <Route exact path="/auth">
            <LoginForm />
          </Route>
        </Switch>
      </DivPaper>
    </Grid>
  </GridRoot>
)

const GridRoot = styled(Grid)`
  height: 100vh;
`

const GridWithImage = styled(Grid)({
  backgroundImage: `url(${FondoLogo})`,
  backgroundRepeat: 'no-repeat',
  // backgroundColor:
  //   theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
})

const DivPaper = styled('div')(({ theme }) => ({
  margin: theme.spacing(8, 4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export default Auth
