import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import { RootState } from '../../redux/root.reducer'
import PrivateRoute from '../shared/PrivateRoute'
import Routes from '../views/PrivateRoutes'

export const PrivateRoutes = () => {
  const user = useSelector((state: RootState) => state.auth.user)
  return (
    <Switch>
      <PrivateRoute
        path="/panel"
        component={Routes}
        isAuth={user && user.id}
      />
    </Switch>
  )
}
