import React from 'react'
import {
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { StarBorder } from '@mui/icons-material'
import { Box } from '@mui/system'

interface Props {
  title: string
  nested?: boolean
  Icon?: any
  onClick?: any
}

const Item = (props: Props) => (
  <ListItemButton onClick={props.onClick}>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: props.nested ? '1rem' : undefined
    }}>

      <ListItemIcon>
        {
          props.Icon
            ? <props.Icon />
            : <StarBorder />
        }
      </ListItemIcon>
      <ListItemText primary={props.title} />
    </Box>
  </ListItemButton>
)

export default Item
