import {
  IsInt,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min
} from 'class-validator'

export class CreateRankingDuckTypeValueDto {
  @IsString()
  type: string

  @IsUUID()
  collectionId: string

  @IsNumber()
  @IsInt()
  @Min(0)
  points: number
}

export class UpdateRankingDuckTypeValueDto {
  @IsUUID()
  id: string

  @IsOptional()
  @IsString()
  type?: string

  @IsOptional()
  @IsUUID()
  collectionId?: string

  @IsOptional()
  @IsNumber()
  @IsInt()
  @Min(0)
  points?: number
}
