import {
  Length,
  IsUUID,
  IsString,
  IsOptional,
} from 'class-validator'

export class CreateTraitDto {
  @Length(2, 50)
  name: string

  @IsUUID('4')
  typeId: string

  @IsUUID('4')
  collectionId: string

  @IsOptional()
  @IsString()
  rarity?: string
}

export class UpdateTraitDto {
  @IsUUID('4')
  id: string

  @Length(2, 50)
  name: string

  @IsOptional()
  @IsString()
  rarity?: string
}

export class CreateTraitResponseDto {
  id: string

  name: string
}

export class GetTraitResponseDto {
  id: string

  name: string
}
