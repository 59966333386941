/**
 * Developed by Skillful Dev LLC
 * Contact: contact@skillfuldev.com
 * website: https://skillfuldev.com
 * Copyright 2022
 */
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from 'react-query'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { getDefaultWallets } from '@rainbow-me/rainbowkit'
import {
  configureChains,
  createClient,
  mainnet,
  WagmiConfig
} from 'wagmi'
import { SnackbarProvider } from 'notistack'
import { AppNavigation } from './components/navigation/navigation'
import 'moment/locale/es-mx'
import { theme } from './styles/theme'

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer

const { chains, provider } = configureChains(
  [mainnet],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY || '' }), publicProvider()]
)

const { connectors } = getDefaultWallets({
  appName: 'Decoupling NFT',
  chains
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * (1000 * 60) // 2 min
    }
  }
})

const App = () => (
  <QueryClientProvider client={queryClient}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <WagmiConfig client={wagmiClient}>
          <SnackbarProvider
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            autoHideDuration={5000}
          >
            <AppNavigation />
          </SnackbarProvider>
        </WagmiConfig>
      </ThemeProvider>
    </StyledEngineProvider>
  </QueryClientProvider>
)

export default App
