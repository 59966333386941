import API, { AxiosConfig, tryGetAPIHeaders } from '../config/axios.config'
import { Paginated, Res } from '../types/response.types'
import { Canva } from '../types/types'
import { getPaginationParams } from '../utils/param.helper'
import { UpdateCanvaBodyDto } from '../validations/canva.dto'

const resourcePath = 'admin/canvas'
type Resource = Canva

const updateBody = async (dto: UpdateCanvaBodyDto): Promise<Res<Resource>> => {
  const res = await API.put<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  return res.data
}

const list = async (search: string, page: number): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(resourcePath, {
    params: getPaginationParams(search, page),
    headers: tryGetAPIHeaders()
  })
  return res.data
}

const CanvaService = {
  updateBody,
  list
}

export default CanvaService
