import { Box } from '@mui/material'
import RegisterBurnDuckTx from './RegisterBurnDuck'
import RegisterMagicBoxBuyTx from './RegisterMagicBoxBuyTx'

const RegisterTransactionsView = () => (
  <Box sx={{
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem'
  }}>
    <RegisterBurnDuckTx />
    <RegisterMagicBoxBuyTx />
  </Box>
)

export default RegisterTransactionsView
