export interface ICredentials {
  token: string
  user: User
}

export interface Image {
  uri: string
  width: number,
  height: number,
  base64?: string;
}

export enum UserType {
  admin = 'admin',
  user = 'user'
}

export type UserTypes =
  | 'admin'
  | 'user'

export interface User {
  id: string
  fullName: string
  email: string
  wallet: string
  type: UserTypes
}

export interface TraitType {
  id: string
  name: string
}
export interface TraitInstance {
  id: string
  user: User
  trait: Trait
}

export interface Collection {
  id: string
  name: string
  metadataUrl: string
  openSeaCollectionSlug: string
  contractAddress: string
}

export interface Trait {
  id: string
  name: string
  imageUrl?: string
  collectionId: string
  typeId: string
  type: TraitType
  collection: Collection
}

export interface MagicBoxItem {
  id: string
  trait: Trait
}

export interface MagicBox {
  id: string
  name: string
  price: number
  imageUrl?: string
  items: MagicBoxItem[]
  color: string
}

export interface PayableEvent {
  id: string
  name: string
  description: string
  price: number
  recipientWallet: string
}
export interface RankingDuckTypeValue {
  id: string
  type: string
  points: number
  collectionId: string
  collection: Collection
}
export interface BurnDuckTransaction {
  id: string
  transactionHash: string
  tokenId: string
  status: string
  errorData?: string
  missingTraits?: string[]
  userId: string
  collectionId: string
  user: User
  collection: Collection
}

export interface Canva {
  id: string
  user: User
  userId: string
  tokenId: string
  imageUrl: string
  metadata: string
  collection: Collection
  body: TraitInstance
  bodyId: string
}
