import React, { useEffect, useMemo, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import debounce from 'lodash.debounce'
import FormDrawer from '../../../shared/Forms/FormDrawer'
import createValidator from '../../../../utils/class-validator-formik'
import { RQueryKeys } from '../../../../types/react-query'
import { CustomTableFormProps } from '../../../shared/Table/CustomTable'
import { Canva, Trait } from '../../../../types/types'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import CanvaService from '../../../../services/canva.service'
import { UpdateCanvaBodyDto } from '../../../../validations/canva.dto'
import TraitService from '../../../../services/trait.service'

const service = CanvaService
const queryKey = RQueryKeys.canvas
const titleType = 'Canva'

const createInitialValues: UpdateCanvaBodyDto = {
  bodyId: '',
  canvaId: ''
}

const Form = (props: CustomTableFormProps) => {
  const { payload, mode, open } = props
  const [title, setTitle] = useState('')
  const [initialValues, setInitialValues] = useState(createInitialValues)
  const [message, setMessage] = useState('')

  const [searchTrait, setSearchTrait] = useState('')

  const [selectedTrait, setSelectedTrait] = useState<Trait | null>(null)

  const queryClient = useQueryClient()

  const traitQuery = useQuery(RQueryKeys.traits, async () => {
    const res = await TraitService.list(searchTrait, 0)
    return res.data
  }, { keepPreviousData: true })

  useEffect(() => { debounceRefetchTrait() }, [searchTrait])

  const debounceRefetchTrait = useMemo(() => debounce(traitQuery.refetch, DEBOUNCE_THRESHOLD), [])

  const updateMutation = useMutation(service.updateBody, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setInitialValues(createInitialValues)
      setMessage(`${titleType} Updated.`)
    },
    onError: () => setMessage('An Error has occurred')
  })

  useEffect(() => {
    setMessage('')
    if (mode === 'READ') setTitle(`${titleType}`)
    if (mode === 'ADD') setTitle(`Create ${titleType}`)
    if (mode === 'UPDATE') setTitle(`Update ${titleType}`)

    if (payload) {
      const data = payload as Canva
      setInitialValues({
        canvaId: data.id,
        bodyId: ''
      })
    }
  }, [payload, mode, open])

  const onSubmit = async (
    values: UpdateCanvaBodyDto,
    formik: FormikHelpers<UpdateCanvaBodyDto>
  ) => {
    await updateMutation.mutateAsync(values)
    formik.resetForm()
    setSelectedTrait(null)
  }

  const validate = createValidator(UpdateCanvaBodyDto)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => (
        <FormDrawer
          message={message}
          open={props.open}
          handleClose={props.handleClose}
          title={title}
          handleSubmit={() => formik.handleSubmit()}
          mode={props.mode}
          isFormLoading={updateMutation.isLoading}
        >

          <Autocomplete
            loading={traitQuery.isLoading}
            fullWidth
            id="combo-box-collection"
            options={traitQuery.data?.rows ?? []}
            getOptionLabel={(option: Trait) => `${option.name} (${option.type.name}) [${option.collection.name}]`}
            renderInput={
              (params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label="Body"
                fullWidth
                margin="normal"
                variant="standard"
                error={formik.touched.bodyId && Boolean(formik.errors.bodyId)}
                helperText={formik.touched.bodyId && formik.errors.bodyId}
              />
            }
            onInputChange={(_, value) => setSearchTrait(value)}
            onChange={(e: any, value: Trait | null) => {
              setSelectedTrait(value)
              formik.setFieldValue('bodyId', value?.id)
            }}
            disabled={mode === 'READ'}
            value={selectedTrait}
          />
        </FormDrawer>
      )}

    </Formik>
  )
}

export default Form
