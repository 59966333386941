import { Box, styled, TextField } from '@mui/material'
import { FormikProps } from 'formik'
import { CreateMagicBoxDto } from '../../../../../validations/magic-box.dto'

interface Props {
  imagePreview: string | null
  setImagePreview: React.Dispatch<React.SetStateAction<string | null>>
  file: File | null
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  formik: FormikProps<CreateMagicBoxDto>
  mode: string | undefined
}

export const StepBasicInfo = (props: Props) => {
  const {
    formik,
    mode,
    imagePreview,
    setFile,
  } = props

  const onSelectFile = (e: React.ChangeEvent<any>) => {
    if (!e.target.files || e.target.files.length === 0) return

    setFile(e.target.files[0])
  }

  const handleSetPrice = (str: string, formik: FormikProps<CreateMagicBoxDto>) => {
    const value = parseFloat(str)
    if (Number.isNaN(value)) return

    formik.setFieldValue('price', value)
  }

  return (
    <Box sx={{ width: '50%' }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <ImagePreview image={imagePreview!}>
          {imagePreview ? '' : 'No image selected'}
        </ImagePreview>
      </Box>

      {
        mode !== 'READ'
        && <TextField
          type="file"
          variant="standard"
          margin="normal"
          id="image"
          label="Image"
          name="image"
          onChange={onSelectFile}
        />
      }

      <TextField
        type="text"
        variant="standard"
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        value={formik.values.name}
        autoFocus
        onChange={formik.handleChange('name')}
        disabled={props.mode === 'READ'}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />

      <TextField
        type="text"
        variant="standard"
        margin="normal"
        required
        fullWidth
        id="price"
        label="Price"
        name="price"
        value={formik.values.price.toString()}
        onChange={(e) => handleSetPrice(e.target.value, formik)}
        disabled={props.mode === 'READ'}
        error={formik.touched.price && Boolean(formik.errors.price)}
        helperText={formik.touched.price && formik.errors.price}
      />

      <TextField
        type="text"
        variant="standard"
        margin="normal"
        required
        fullWidth
        id="color"
        label="Text shadow color"
        name="color"
        value={formik.values.color}
        onChange={formik.handleChange('color')}
        disabled={props.mode === 'READ'}
        error={formik.touched.color && Boolean(formik.errors.color)}
        helperText={formik.touched.color && formik.errors.color}
      />

    </Box>
  )
}

const ImagePreview = styled('div') <{ image?: string }>`
  display: flex ;
  justify-content:  center;
  align-items: center ;
  background-image: ${(props) => (props.image ? `url(${props.image})` : '')} ;
  background-repeat: no-repeat ;
  background-size: contain ;
  width: 150px;
  height: 150px;
  color: gray;
`
