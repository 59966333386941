import * as React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  styled,
  Typography,
} from '@mui/material'

export interface FormDrawerProps {
  open?: boolean
  handleClose?: any,
  children?: any
  mode?: 'ADD' | 'UPDATE' | 'READ',
  Icon?: React.FC<any>
  payload?: any
  message?: string
  isFormLoading: boolean
  minWidth?: string
}

const FormDrawerSimple = (props: FormDrawerProps) => {
  const {
    open,
    handleClose,
    isFormLoading,
    message,
    minWidth = '15vw',
  } = props

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      // material sets an index of about 1200
      sx={{ zIndex: 1300, width: '100%' }}
    >
      <InnerContainer sx={{ minWidth }}>
        {
          isFormLoading
          && (
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          )
        }
        {
          (!isFormLoading && message)
          && (
            <>
              <Box marginTop={2}>
                <Typography
                  variant="body1"
                  gutterBottom
                >
                  {props.message}
                </Typography>
              </Box>
              <Grid item xs>
                <SubmitButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={props.handleClose}
                >
                  Cerrar formulario
                </SubmitButton>
              </Grid>
            </>
          )
        }
        {
          (!isFormLoading && !message)
          && (
            <>
              {props.children}
            </>
          )
        }
      </InnerContainer>
    </Drawer>
  )
}

const SubmitButton = styled(Button)({
  margin: '10%'
})

const InnerContainer = styled('div')({
  minWidth: '15vw',
  marginTop: '3%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const CircularProgressContainer = styled('div')({
  width: '100%',
  margin: '4rem',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  flexWrap: 'wrap',
  alignItems: 'center',
})

export default FormDrawerSimple
