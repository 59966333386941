// eslint-disable react/destructuring-assignment
import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'
import { LockOutlined } from '@mui/icons-material'
import Copyright from '../../shared/Copyright'
import createValidator from '../../../utils/class-validator-formik'
import { LoginDto } from '../../../validations/basic/auth.dto'
import { getErrorMessage } from '../../../utils/error.helper'
import { AppDispatch } from '../../../redux/store'
import * as auth from '../../../redux/auth.reducer'
import { RootState } from '../../../redux/root.reducer'
import { ELinks } from '../../navigation/navigation.types'
import { getCredentials } from '../../../utils/credentials.helper'
import { ICredentials } from '../../../types/types'

const initialValues: LoginDto = {
  email: '',
  password: '',
}

if (process.env.REACT_APP_ENV === 'DEV') {
  initialValues.email = 'admin@decoupling.app'
  initialValues.password = 'Test123456'
}

const Login = (props: Props) => {
  const {
    login, loginError, clearLoginError, refresh
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const validate = createValidator(LoginDto)

  useEffect(() => {
    setIsLoading(true)
    const credentials = getCredentials()

    if (credentials) {
      refresh(credentials)
      history.replace(ELinks.Panel)
    }

    setIsLoading(false)
  }, [])

  const onSubmit = async (values: LoginDto) => {
    clearLoginError()
    setIsLoading(true)

    const error = await login(values)
    setIsLoading(false)

    if (!error) {
      history.replace(ELinks.Panel)
    }
  }

  return (
    <>
      <Avatar>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      {
        isLoading
        && (
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        )
      }
      {
        loginError && <Alert severity="error">{getErrorMessage(loginError)}</Alert>
      }
      {
        !isLoading
        && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}

                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Clave"
                  type="password"
                  id="clave"
                  autoComplete="current-password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <SubmitButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Iniciar sesion
                </SubmitButton>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </Form>
            )}

          </Formik>
        )
      }
    </>
  )
}

const Form = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  height: '100%',
  marginTop: theme.spacing(1),
}))

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}))

const CircularProgressContainer = styled('div')({
  width: '100%',
  margin: '4rem',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  flexWrap: 'wrap',
  alignItems: 'center',
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  login: async (value: LoginDto) => dispatch(auth.login(value)),
  clearLoginError: () => dispatch(auth.loginErrorAC(null)),
  refresh: async (obj: ICredentials) => dispatch(auth.refreshUser(obj))
})

const mapStateToProps = (state: RootState) => ({
  loginError: state.auth.loginErrorCode,
})
const connectToStore = connect(mapStateToProps, mapDispatchToProps)
interface Props extends ConnectedProps<typeof connectToStore> { }

export default connectToStore(Login)
