import { Button, Grid } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'

const Home = () => {
  const history = useHistory()

  const goLogin = () => history.push('/auth')

  return (
    <Grid
      style={{ height: '100vh' }}
      container
      alignItems="center"
      justifyContent="center"
    >
      <Button
        variant="contained"
        color="primary"
        onClick={goLogin}
      >
        Login
      </Button>
    </Grid>
  )
}

export default Home
