import React, { useEffect, useMemo, useState } from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { CreditCard } from '@mui/icons-material'
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import debounce from 'lodash.debounce'
import FormDrawer from '../../../shared/Forms/FormDrawer'
import createValidator from '../../../../utils/class-validator-formik'
import { RQueryKeys } from '../../../../types/react-query'
import { CustomTableFormProps } from '../../../shared/Table/CustomTable'
import RankingDuckTypeService from '../../../../services/ranking-duck-type-value.service'
import { CreateRankingDuckTypeValueDto, UpdateRankingDuckTypeValueDto } from '../../../../validations/admin-ranking.dto'
import CollectionService from '../../../../services/collection.service'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'
import { Collection } from '../../../../types/types'

const service = RankingDuckTypeService
const queryKey = RQueryKeys.rankingDuckTypeValues
const titleType = 'Duck Type'

const createInitialValues: CreateRankingDuckTypeValueDto = {
  type: '',
  collectionId: '',
  points: 0
}

const Form = (props: CustomTableFormProps) => {
  const { payload, mode, open } = props
  const [title, setTitle] = useState('')
  const [initialValues, setInitialValues] = useState(createInitialValues)
  const [message, setMessage] = useState('')
  const queryClient = useQueryClient()

  const [searchCollection, setSearchCollection] = useState('')
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null)

  const collectionQuery = useQuery(RQueryKeys.collections, async () => {
    const res = await CollectionService.list(searchCollection, 0)
    return res.data
  }, { keepPreviousData: true })

  const createMutation = useMutation(service.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setMessage(`${titleType} sucessfully created.`)
    },
    onError: () => setMessage('An error has occurred')
  })

  useEffect(() => { debounceRefetchCollection() }, [searchCollection])
  const debounceRefetchCollection = useMemo(() => debounce(collectionQuery.refetch, DEBOUNCE_THRESHOLD), [])

  const updateMutation = useMutation(service.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setInitialValues(createInitialValues)
      setMessage(`${titleType} Updated.`)
    },
    onError: () => setMessage('An Error has occurred')
  })

  useEffect(() => {
    setMessage('')
    if (mode === 'READ') setTitle(`${titleType}`)
    if (mode === 'ADD') setTitle(`Create ${titleType}`)
    if (mode === 'UPDATE') setTitle(`Update ${titleType}`)

    if (mode === 'UPDATE' || mode === 'READ') {
      setInitialValues(payload)
    }

    if (mode === 'ADD') {
      setInitialValues(createInitialValues)
    }
  }, [payload, mode, open])

  const handleSetPoints = (str: string, formik: FormikProps<CreateRankingDuckTypeValueDto>) => {
    const value = parseFloat(str)
    if (Number.isNaN(value)) return

    formik.setFieldValue('points', value)
  }

  const onSubmit = async (
    values: CreateRankingDuckTypeValueDto | UpdateRankingDuckTypeValueDto,
    formik: FormikHelpers<CreateRankingDuckTypeValueDto>
  ) => {
    if (mode === 'ADD') {
      await createMutation.mutateAsync(values as CreateRankingDuckTypeValueDto)
    } else {
      await updateMutation.mutateAsync(values as UpdateRankingDuckTypeValueDto)
    }
    formik.resetForm()
  }

  const validate = mode === 'ADD'
    ? createValidator(CreateRankingDuckTypeValueDto)
    : createValidator(UpdateRankingDuckTypeValueDto)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => (
        <FormDrawer
          message={message}
          open={props.open}
          handleClose={props.handleClose}
          title={title}
          Icon={CreditCard}
          handleSubmit={() => formik.handleSubmit()}
          mode={props.mode}
          isFormLoading={createMutation.isLoading || updateMutation.isLoading}
        >
          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="type"
            label="Type"
            name="type"
            value={formik.values.type}
            autoFocus
            onChange={formik.handleChange('type')}
            disabled={props.mode === 'READ'}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          />

          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="points"
            label="Points"
            name="points"
            value={formik.values.points}
            autoFocus
            onChange={(e) => handleSetPoints(e.target.value, formik)}
            disabled={props.mode === 'READ'}
            error={formik.touched.points && Boolean(formik.errors.points)}
            helperText={formik.touched.points && formik.errors.points}
          />

          <Autocomplete
            loading={collectionQuery.isLoading}
            fullWidth
            id="combo-box-collection"
            options={collectionQuery.data?.rows ?? []}
            getOptionLabel={(option: Collection) => `${option.name}`}
            renderInput={
              (params: AutocompleteRenderInputParams) => <TextField
                {...params}
                label="Collection"
                fullWidth
                margin="normal"
                variant="standard"
                error={formik.touched.collectionId && Boolean(formik.errors.collectionId)}
                helperText={formik.touched.collectionId && formik.errors.collectionId}
              />
            }
            onInputChange={(_, value) => setSearchCollection(value)}
            onChange={(e: any, value: Collection | null) => {
              setSelectedCollection(value)
              formik.setFieldValue('collectionId', value?.id)
            }}
            disabled={mode === 'READ'}
            value={selectedCollection}
          />
        </FormDrawer>
      )}

    </Formik>
  )
}

export default Form
