import API, { AxiosConfig, tryGetAPIHeaders } from '../config/axios.config'
import { Paginated, Res } from '../types/response.types'
import { PayableEvent } from '../types/types'
import { getPaginationParams } from '../utils/param.helper'
import { CreatePayableEventDto, UpdatePayableEventDto } from '../validations/payable-event.dto'

const resourcePath = '/admin/payable-events'
type Resource = PayableEvent

const create = async (dto: CreatePayableEventDto): Promise<Res<Resource>> => {
  const res = await API.post<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  return res.data
}

const update = async (dto: UpdatePayableEventDto): Promise<Res<Resource>> => {
  const res = await API.put<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  return res.data
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${resourcePath}/${id}`, AxiosConfig())
}

const list = async (search: string, page: number): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(resourcePath, {
    params: getPaginationParams(search, page),
    headers: tryGetAPIHeaders()
  })
  return res.data
}

const PayableEventService = {
  create,
  update,
  deleteOne,
  list
}

export default PayableEventService
