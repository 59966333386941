import { logout } from './auth.reducer'

enum SharedActionTypes {
  SET_LOGOUT_DIALOG = 'SET_LOGOUT_DIALOG',
  LOGOUT_FROM_TOKEN_EXPIRED = 'LOGOUT_FROM_TOKEN_EXPIRED',
}

interface IState {
  isLogoutDialogOpen: boolean
  isTokenExpired: boolean
}

type SharedAction =
  | { type: SharedActionTypes.SET_LOGOUT_DIALOG, payload: boolean }
  | { type: SharedActionTypes.LOGOUT_FROM_TOKEN_EXPIRED, payload: null }

const logoutFromTokenExpiredAC = (): SharedAction => ({
  type: SharedActionTypes.LOGOUT_FROM_TOKEN_EXPIRED,
  payload: null,
})
const setLogoutDialogAC = (obj: boolean): SharedAction => ({
  type: SharedActionTypes.SET_LOGOUT_DIALOG,
  payload: obj,
})

export const setLogoutDialog = (obj: boolean) => (dispatch: any) => {
  dispatch(setLogoutDialogAC(obj))
}

export const logoutFromExpiredToken = () => (dispatch: any) => {
  dispatch(logoutFromTokenExpiredAC())
  setTimeout(() => dispatch(logout()), 2000)
}

// #endregion
export const initialState: IState = {
  isLogoutDialogOpen: false,
  isTokenExpired: false,
}

const LogoutReducer = (state: IState = initialState, action: SharedAction): IState => {
  switch (action.type) {
    case SharedActionTypes.SET_LOGOUT_DIALOG:
      return {
        ...state,
        isLogoutDialogOpen: action.payload,
      }
    case SharedActionTypes.LOGOUT_FROM_TOKEN_EXPIRED:
      return {
        ...state,
        isTokenExpired: true,
      }
    default: return state
  }
}

export default LogoutReducer
