import {
  ArrayNotEmpty, ArrayUnique, IsArray, IsUUID
} from 'class-validator'

export class CreateTraitInstanceDto {
  @IsUUID()
  userId: string

  @IsArray()
  @ArrayNotEmpty()
  @ArrayUnique()
  traitIds: string[]
}
