import React, { useEffect, useMemo, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  styled,
  TextField
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import debounce from 'lodash.debounce'
import { Box } from '@mui/system'
import FormDrawer from '../../../shared/Forms/FormDrawer'
import createValidator from '../../../../utils/class-validator-formik'
import { RQueryKeys } from '../../../../types/react-query'
import { CustomTableFormProps } from '../../../shared/Table/CustomTable'
import { CreateTraitDto, UpdateTraitDto } from '../../../../validations/trait.dto'
import TraitService from '../../../../services/trait.service'
import { Collection, Trait, TraitType } from '../../../../types/types'
import CollectionService from '../../../../services/collection.service'
import TraitTypeService from '../../../../services/trait-type.service'
import { DEBOUNCE_THRESHOLD } from '../../../../config/debounce.config'

const service = TraitService
const queryKey = RQueryKeys.traits
const titleType = 'Trait'

const createInitialValues: CreateTraitDto = {
  name: '',
  collectionId: '',
  typeId: '',
}

const Form = (props: CustomTableFormProps) => {
  const { payload, mode, open } = props
  const [title, setTitle] = useState('')
  const [initialValues, setInitialValues] = useState(createInitialValues)
  const [message, setMessage] = useState('')

  const [file, setFile] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string | null>(null)

  const [searchCollection, setSearchCollection] = useState('')
  const [searchTraitType, setSearchTraitType] = useState('')

  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null)
  const [selectedTraitType, setSelectedTraitType] = useState<TraitType | null>(null)

  const queryClient = useQueryClient()

  const collectionQuery = useQuery(RQueryKeys.collections, async () => {
    const res = await CollectionService.list(searchCollection, 0)
    return res.data
  }, { keepPreviousData: true })

  const traitTypeQuery = useQuery(RQueryKeys.traitType, async () => {
    const res = await TraitTypeService.list(searchTraitType, 0)
    return res.data
  }, { keepPreviousData: true })

  useEffect(() => { debounceRefetchCollection() }, [searchCollection])
  useEffect(() => { debounceRefetchTraitType() }, [searchTraitType])

  const debounceRefetchCollection = useMemo(() => debounce(collectionQuery.refetch, DEBOUNCE_THRESHOLD), [])
  const debounceRefetchTraitType = useMemo(() => debounce(traitTypeQuery.refetch, DEBOUNCE_THRESHOLD), [])

  const createMutation = useMutation(service.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setMessage(`${titleType} sucessfully created.`)
    },
    onError: () => setMessage('An error has occurred')
  })

  const updateMutation = useMutation(service.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setInitialValues(createInitialValues)
      setMessage(`${titleType} Updated.`)
    },
    onError: () => setMessage('An Error has occurred')
  })

  useEffect(() => {
    setMessage('')
    if (mode === 'READ') setTitle(`${titleType}`)
    if (mode === 'ADD') setTitle(`Create ${titleType}`)
    if (mode === 'UPDATE') setTitle(`Update ${titleType}`)

    if (mode === 'UPDATE' || mode === 'READ') {
      const data = payload as Trait
      setInitialValues({
        ...data,
        collectionId: data.collection.id,
        typeId: data.type.id,
      })
      setImagePreview(data.imageUrl || null)
      setSelectedCollection(data.collection)
      setSelectedTraitType(data.type)
    }

    if (mode === 'ADD') {
      setInitialValues(createInitialValues)
      setImagePreview(null)
      setFile(null)
    }
  }, [payload, mode, open])

  useEffect(() => {
    if (file) {
      const img = URL.createObjectURL(file)
      setImagePreview(img)
    } else {
      setImagePreview(null)
    }
  }, [file])

  const onSelectFile = (e: React.ChangeEvent<any>) => {
    if (!e.target.files || e.target.files.length === 0) return

    setFile(e.target.files[0])
  }

  const onSubmit = async (
    values: CreateTraitDto | UpdateTraitDto,
    formik: FormikHelpers<CreateTraitDto>
  ) => {
    if (mode === 'ADD' && !file) {
      return
    }

    if (mode === 'ADD') {
      await createMutation.mutateAsync({
        dto: values as CreateTraitDto,
        image: file!
      })
    } else {
      await updateMutation.mutateAsync({
        dto: values as UpdateTraitDto,
        image: file!
      })
    }
    setFile(null)
    setImagePreview(null)
    formik.resetForm()
    setSelectedCollection(null)
    setSelectedTraitType(null)
  }

  const validate = mode === 'ADD'
    ? createValidator(CreateTraitDto)
    : createValidator(UpdateTraitDto)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => (
        <FormDrawer
          message={message}
          open={props.open}
          handleClose={props.handleClose}
          title={title}
          handleSubmit={() => formik.handleSubmit()}
          mode={props.mode}
          isFormLoading={createMutation.isLoading || updateMutation.isLoading}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <ImagePreview image={imagePreview!}>
              {imagePreview ? '' : 'No image selected'}
            </ImagePreview>
          </Box>

          {
            mode !== 'READ'
            && <TextField
              type="file"
              variant="standard"
              margin="normal"
              id="image"
              label="Image"
              name="image"
              onChange={onSelectFile}
            />
          }

          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formik.values.name}
            autoFocus
            onChange={formik.handleChange('name')}
            disabled={props.mode === 'READ'}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          {
            mode !== 'UPDATE'
            && <Autocomplete
              loading={collectionQuery.isLoading}
              fullWidth
              id="combo-box-collection"
              options={collectionQuery.data?.rows ?? []}
              getOptionLabel={(option: Collection) => `${option.name}`}
              renderInput={
                (params: AutocompleteRenderInputParams) => <TextField
                  {...params}
                  label="Collection"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  error={formik.touched.collectionId && Boolean(formik.errors.collectionId)}
                  helperText={formik.touched.collectionId && formik.errors.collectionId}
                />
              }
              onInputChange={(_, value) => setSearchCollection(value)}
              onChange={(e: any, value: Collection | null) => {
                setSelectedCollection(value)
                formik.setFieldValue('collectionId', value?.id)
              }}
              disabled={mode === 'READ'}
              value={selectedCollection}
            />
          }
          {
            mode !== 'UPDATE'
            && <Autocomplete
              loading={traitTypeQuery.isLoading}
              fullWidth
              id="combo-box-type"
              options={traitTypeQuery.data?.rows ?? []}
              getOptionLabel={(option: TraitType) => `${option.name}`}
              renderInput={
                (params: AutocompleteRenderInputParams) => <TextField
                  {...params}
                  label="Type"
                  fullWidth
                  margin="normal"
                  variant="standard"
                  error={formik.touched.typeId && Boolean(formik.errors.typeId)}
                  helperText={formik.touched.typeId && formik.errors.typeId}
                />
              }
              onInputChange={(_, value) => setSearchTraitType(value)}
              onChange={(e: any, value: TraitType | null) => {
                setSelectedTraitType(value)
                formik.setFieldValue('typeId', value?.id)
              }}
              disabled={mode === 'READ'}
              value={selectedTraitType}
            />

          }

        </FormDrawer>
      )}

    </Formik>
  )
}

const ImagePreview = styled('div') <{ image?: string }>`
  display: flex ;
  justify-content:  center;
  align-items: center ;
  background-image: ${(props) => (props.image ? `url(${props.image})` : '')} ;
  background-repeat: no-repeat ;
  background-size: contain ;
  width: 250px;
  height: 250px;
  color: gray
`

export default Form
