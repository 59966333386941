import API, { AxiosConfig, getAPIHeaders, tryGetAPIHeaders } from '../config/axios.config'
import { Paginated, Res, UpdateImageResDto } from '../types/response.types'
import { Trait } from '../types/types'
import { getPaginationParams } from '../utils/param.helper'
import { CreateTraitDto, UpdateTraitDto } from '../validations/trait.dto'

const resourcePath = 'admin/traits'
type Resource = Trait

const create = async (obj: { dto: CreateTraitDto, image: File }): Promise<Res<Resource>> => {
  const { dto, image } = obj
  const res = await API.post<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  const resData = res.data

  const imageRes = await updateProductImage(image, resData.data.id)
  resData.data = { ...resData.data, ...imageRes.data }

  return resData
}

const update = async (obj: { dto: UpdateTraitDto, image: File | null }): Promise<Res<Resource>> => {
  const { dto, image } = obj
  const res = await API.put<Res<Resource>>(resourcePath, dto, await AxiosConfig())
  const resData = res.data

  if (image) {
    const imageRes = await updateProductImage(image, dto.id)
    resData.data = { ...resData.data, ...imageRes.data }
  }

  return resData
}

const deleteOne = async (id: string): Promise<void> => {
  await API.delete(`${resourcePath}/${id}`, AxiosConfig())
}

const list = async (search: string, page: number): Promise<Res<Paginated<Resource>>> => {
  const res = await API.get<Res<Paginated<Resource>>>(resourcePath, {
    params: getPaginationParams(search, page),
    headers: tryGetAPIHeaders()
  })
  return res.data
}

const updateProductImage = async (image: File, traitId: string) => {
  const formData = new FormData()
  formData.append('file', image)

  const res = await API.put<Res<UpdateImageResDto>>(`${resourcePath}/image/${traitId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...getAPIHeaders()
    }
  })
  return res.data
}

const TraitService = {
  create,
  update,
  deleteOne,
  list
}

export default TraitService
