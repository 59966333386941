import React, { useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { CreditCard } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import FormDrawer from '../../../shared/Forms/FormDrawer'
import createValidator from '../../../../utils/class-validator-formik'
import { RQueryKeys } from '../../../../types/react-query'
import { CustomTableFormProps } from '../../../shared/Table/CustomTable'
import CollectionService from '../../../../services/collection.service'
import { CreateCollectionDto, UpdateCollectionDto } from '../../../../validations/collection.dto'

const service = CollectionService
const queryKey = RQueryKeys.collections
const titleType = 'Collection'

const createInitialValues: CreateCollectionDto = {
  name: '',
  metadataUrl: '',
  openSeaCollectionSlug: ''
}

const Form = (props: CustomTableFormProps) => {
  const { payload, mode, open } = props
  const [title, setTitle] = useState('')
  const [initialValues, setInitialValues] = useState(createInitialValues)
  const [message, setMessage] = useState('')
  const queryClient = useQueryClient()

  const createMutation = useMutation(service.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setMessage(`${titleType} sucessfully created.`)
    },
    onError: () => setMessage('An error has occurred')
  })

  const updateMutation = useMutation(service.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setInitialValues(createInitialValues)
      setMessage(`${titleType} Updated.`)
    },
    onError: () => setMessage('An Error has occurred')
  })

  useEffect(() => {
    setMessage('')
    if (mode === 'READ') setTitle(`${titleType}`)
    if (mode === 'ADD') setTitle(`Create ${titleType}`)
    if (mode === 'UPDATE') setTitle(`Update ${titleType}`)

    if (mode === 'UPDATE' || mode === 'READ') {
      setInitialValues(payload)
    }

    if (mode === 'ADD') {
      setInitialValues(createInitialValues)
    }
  }, [payload, mode, open])

  const onSubmit = async (
    values: CreateCollectionDto | UpdateCollectionDto,
    formik: FormikHelpers<CreateCollectionDto>
  ) => {
    if (mode === 'ADD') {
      await createMutation.mutateAsync(values as CreateCollectionDto)
    } else {
      await updateMutation.mutateAsync(values as UpdateCollectionDto)
    }
    formik.resetForm()
  }

  const validate = mode === 'ADD'
    ? createValidator(CreateCollectionDto)
    : createValidator(UpdateCollectionDto)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => (
        <FormDrawer
          message={message}
          open={props.open}
          handleClose={props.handleClose}
          title={title}
          Icon={CreditCard}
          handleSubmit={() => formik.handleSubmit()}
          mode={props.mode}
          isFormLoading={createMutation.isLoading || updateMutation.isLoading}
        >
          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formik.values.name}
            autoFocus
            onChange={formik.handleChange('name')}
            disabled={props.mode === 'READ'}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="openSeaCollectionSlug"
            label="OpenSea Slug"
            name="openSeaCollectionSlug"
            value={formik.values.openSeaCollectionSlug}
            autoFocus
            onChange={formik.handleChange('openSeaCollectionSlug')}
            disabled={props.mode === 'READ'}
            error={formik.touched.openSeaCollectionSlug && Boolean(formik.errors.openSeaCollectionSlug)}
            helperText={formik.touched.openSeaCollectionSlug && formik.errors.openSeaCollectionSlug}
          />

          <TextField
            type="text"
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="metadataUrl"
            label="Metadata URL"
            name="metadataUrl"
            value={formik.values.metadataUrl}
            autoFocus
            onChange={formik.handleChange('metadataUrl')}
            disabled={props.mode === 'READ'}
            error={formik.touched.metadataUrl && Boolean(formik.errors.metadataUrl)}
            helperText={formik.touched.metadataUrl && formik.errors.metadataUrl}
          />
        </FormDrawer>
      )}

    </Formik>
  )
}

export default Form
