import { Route, Switch } from 'react-router-dom'
import SideNavbar from '../../shared/Navbars/SideNavbar'
import CollectionView from './Collection'
import { PanelDashboard } from './Dashboard'
import TraitView from './Trait'
import TraitTypeView from './TraitType'
import UserView from './User/Users'
import TraitInstanceView from './TraitInstance'
import MagicBoxView from './MagicBox'
import PayableEventView from './PayableEvent'
import DuckTypeView from './DuckType'
import BurnDuckTransactionView from './BurnDuckTransaction'
import CanvaView from './Canva'
import RegisterTransactionsView from './RegisterTransactions'

const Routes = () => (
  <SideNavbar title="Panel admin">
    <Switch>
      <Route
        path="/panel/dashboard"
        exact
        component={PanelDashboard}
      />

      <Route
        path="/panel/users"
        exact
        component={UserView}
      />

      <Route
        path="/panel/trait-types"
        exact
        component={TraitTypeView}
      />

      <Route
        path="/panel/collections"
        exact
        component={CollectionView}
      />

      <Route
        path="/panel/traits"
        exact
        component={TraitView}
      />

      <Route
        path="/panel/trait-instances"
        exact
        component={TraitInstanceView}
      />

      <Route
        path="/panel/magic-boxes"
        exact
        component={MagicBoxView}
      />

      <Route
        path="/panel/payable-events"
        exact
        component={PayableEventView}
      />

      <Route
        path="/panel/burn-ducks-tx"
        exact
        component={BurnDuckTransactionView}
      />

      <Route
        path="/panel/canvas"
        exact
        component={CanvaView}
      />

      <Route
        path="/panel/transactions"
        exact
        component={RegisterTransactionsView}
      />

      <Route
        path="/panel/duck-types"
        exact
        component={DuckTypeView}
      />
    </Switch>
  </SideNavbar>
)

export default Routes
