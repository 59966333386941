import React, { useEffect, useState } from 'react'
import { CreditCard } from '@mui/icons-material'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import { useMutation, useQueryClient } from 'react-query'
import { Stack } from '@mui/system'
import FormDrawer from '../../../shared/Forms/FormDrawer'
import { RQueryKeys } from '../../../../types/react-query'
import { CustomTableFormProps } from '../../../shared/Table/CustomTable'
import BurnDuckTxService from '../../../../services/burn-duck-tx.service'
import { BurnDuckTransaction } from '../../../../types/types'

const service = BurnDuckTxService
const queryKey = RQueryKeys.burnDuckTransactions
const titleType = 'Burn duck Transaction'

const Form = (props: CustomTableFormProps) => {
  const { payload, mode, open } = props
  const [title, setTitle] = useState('')
  const [initialValues, setInitialValues] = useState<BurnDuckTransaction | null>(null)
  const [message, setMessage] = useState('')
  const queryClient = useQueryClient()

  const markAsCompletedMutation = useMutation(service.markAsCompleted, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      setMessage(`${titleType} sucessfully marked as completed.`)
    },
    onError: () => setMessage('An error has occurred')
  })

  useEffect(() => {
    setMessage('')
    if (mode === 'READ') setTitle(`${titleType}`)
    if (mode === 'UPDATE' || mode === 'READ') {
      setInitialValues(payload)
    }
  }, [payload, mode, open])

  const markAsCompleted = async () => {
    if (!initialValues) return
    await markAsCompletedMutation.mutateAsync(initialValues.id)
  }

  return (
    <FormDrawer
      message={message}
      open={props.open}
      handleClose={props.handleClose}
      title={title}
      Icon={CreditCard}
      handleSubmit={() => { }}
      mode={props.mode}
      isFormLoading={markAsCompletedMutation.isLoading}
    >
      <Stack gap={1} maxWidth={'30rem'}>
        <Typography>{`Status: ${initialValues?.status}`}</Typography>
        <Typography>{`Transaction Hash: ${initialValues?.transactionHash}`}</Typography>
        <Typography>{`Duck ID: ${initialValues?.tokenId}`}</Typography>
        <Typography>{`Collection: ${initialValues?.collection.name}`}</Typography>
        <Typography>
          {`Missing traits: ${initialValues?.missingTraits ? '' : '0'}`}</Typography>

        {
          initialValues?.missingTraits?.length
          && <List>
            {
              initialValues.missingTraits.map((x, i) => (
                <ListItem key={i}>
                  <ListItemText
                    primary={`- ${x}`}
                  />
                </ListItem>
              ))
            }
          </List>
        }

        {
          initialValues?.errorData
          && <Typography>{initialValues.errorData}</Typography>
        }
        <Button
          variant='contained'
          onClick={() => markAsCompleted()}
          disabled={markAsCompletedMutation.isLoading}
        >
          Mark as completed
        </Button>
      </Stack>
    </FormDrawer>
  )
}

export default Form
