import { Delete } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography
} from '@mui/material'
import { FormikProps } from 'formik'
import debounce from 'lodash.debounce'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { v4 } from 'uuid'
import { DEBOUNCE_THRESHOLD } from '../../../../../config/debounce.config'
import TraitService from '../../../../../services/trait.service'
import { RQueryKeys } from '../../../../../types/react-query'
import { Trait } from '../../../../../types/types'
import { CreateMagicBoxDto } from '../../../../../validations/magic-box.dto'
import { SelectedTrait } from './Form'

interface Props {
  selectedTraits: SelectedTrait[]
  setSelectedTraits: React.Dispatch<React.SetStateAction<SelectedTrait[]>>
  formik: FormikProps<CreateMagicBoxDto>
  mode: string | undefined
}

export const StepSelectTraits = (props: Props) => {
  const { selectedTraits, formik, setSelectedTraits } = props

  const [searchTraits, setSearchTraits] = useState('')
  const [selected, setSelected] = useState(null)

  const traitQuery = useQuery(RQueryKeys.traits, async () => {
    const res = await TraitService.list(searchTraits, 0)
    return res.data
  }, { keepPreviousData: true })

  const debounceRefetchTrait = useMemo(() => debounce(traitQuery.refetch, DEBOUNCE_THRESHOLD), [])

  useEffect(() => {
    debounceRefetchTrait()
  }, [searchTraits])

  const handleRemove = (id: string) => {
    setSelectedTraits((prev) => prev.filter((x) => x.itemId !== id))
  }

  useEffect(() => {
    if (searchTraits) {
      setSearchTraits('')
      setSelected(null)
    }
  }, [selectedTraits])

  const handleSelectTrait = (formik: FormikProps<CreateMagicBoxDto>, value: Trait | null) => {
    if (!value) return
    const selectedTrait: SelectedTrait = { itemId: v4(), trait: value }
    setSelectedTraits((prev) => [...prev, selectedTrait])

    const ids = selectedTraits.map((x) => x.trait.id)
    formik.setFieldValue('traitIds', [...ids, value.id])
  }

  return (
    <Box sx={{
      height: '100%',
      width: '70%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      {
        props.mode !== 'READ'
        && <Autocomplete
          loading={traitQuery.isLoading}
          fullWidth
          id="combo-box-trait"
          options={traitQuery.data?.rows || []}
          getOptionLabel={(option: Trait) => `${option.name} (${option.type.name}) [${option.collection.name}]`}
          renderInput={
            (params: AutocompleteRenderInputParams) => <TextField
              {...params}
              label="Traits"
              fullWidth
              variant="standard"
              error={formik.touched.traitIds && Boolean(formik.errors.traitIds)}
              helperText={formik.touched.traitIds && formik.errors.traitIds}
            />
          }
          onInputChange={(_, value) => setSearchTraits(value)}
          disabled={props.mode === 'READ'}
          onChange={(e: any, value) => handleSelectTrait(formik, value)}
          value={selected}
          inputValue={searchTraits}
        />
      }

      <Typography marginY={'1rem'}>Selected traits:</Typography>
      <List
        sx={{
          height: '28rek',
          width: '100%',
          maxHeight: '28rem',
          overflow: 'scroll'
        }}
      >
        {
          selectedTraits.map(({ itemId, trait }) => (
            <ListItem
              key={`selected-${itemId}`}
              secondaryAction={
                props.mode !== 'READ'
                && <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemove(itemId)}
                >
                  <Delete />
                </IconButton>
              }
            >
              <Typography>{`${trait.name} (${trait.type.name}) [${trait.collection.name}]`}</Typography>
            </ListItem>
          ))
        }
      </List>

    </Box>
  )
}
